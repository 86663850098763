import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./Features/Common/ScrollToTop";
import Login from "./Features/Auth/Login";
import CommonLayout from "./Layout/CommonLayout";
import Profile from "./Features/Common/Profile";
import ManageUsers from "./Features/Admin/ManageUsers";
import Home from "./Features/Common/Home";
import PageNotFound from "./Features/Common/PageNotFound";
import ManageSurveys from "./Features/Admin/ManageSurveys";
import ViewSurvey from "./Features/Admin/ViewSurvey";
import PrivacyPolicy from "./Features/Common/PrivacyPolicy";
import ManageContractors from "./Features/Admin/ManageContractors";
import DaywiseReport from "./Features/Admin/DaywiseReport";
import VerificationReport from "./Features/Admin/VerificationReport";
import ManageFireOfficers from "./Features/Admin/ManageFireOfficers";
import NearbyReport from "./Features/Admin/NearbyReport";
import SurveyImages from "./Features/Admin/SurveyImages";
import ViewContractors from "./Features/Admin/ViewContractors";
import SurveyImgVerification from "./Features/Admin/SurveyImgVerification";
import Home2 from "./Features/Common/Home2";
import ManageMavims from "./Features/Admin/ManageMavims";
import Formone from "./Features/Citizen/Formone";
import Formtwo from "./Features/Citizen/Formtwo";
import Formthree from "./Features/Citizen/Formthree";
import CitizenLayout from "./Layout/CitizenLayout";
import CrossVerification from "./Features/Admin/CrossVerification";
import SmsCampaign from "./Features/Admin/SmsCampaign";
import RejectionReport from "./Features/Admin/RejectionReport";
import PromoterVerify from "./Features/Admin/PromoterVerify";
import ManageReSurveys from "./Features/Admin/ManageReSurvey";
import NoticeToBeGiven from "./Features/Notice/NoticeToBeGiven";
import NoticeGenerated from "./Features/Notice/NoticeGenerated";
import NoticeSent from "./Features/Notice/NoticeSent";
import OffiecerManageSurveys from "./Features/Admin/OfficerManageSurvey";
import OfficerHome from "./Features/Common/OfficerHome";
import OfficerViewSurvey from "./Features/Admin/OfficerViewSurvey";
import ViewNotice from "./Features/Notice/ViewNotice";
import SendNoticeToMobile from "./Features/Notice/SendNoticeToMobile";
import OffiecrManageUsers from "./Features/Admin/OffiecrManageUsers";
import OfficerReport from "./Features/Admin/OfficerReport";
import OfficerWiseReports from "./Features/Admin/OfficerWiseReports";
import GenerateNotice from "./Features/Notice/GenerateNotice";
import DownloadMergePdf from "./Features/Notice/DownloadMergePdf";
import BillBajawaliList from "./Features/Admin/BillBajawaliList";
import NoticeFormat from "./Features/Notice/NoticeFormat";
import AllocateTarget from "./Features/Admin/AllocateTarget";
import AllJaptiKarvai from "./Features/japti/AllJaptiKarvai";
import ComplianceHistory from "./Features/Compliance/ComplianceHistory";
import ComplianceList from "./Features/Compliance/ComplianceList";
import AddCompliance from "./Features/Compliance/AddCompliance";
import DeleteSurveyHistory from "./Features/Admin/DeleteSurveyHistory";
import ViewDeleteSurvey from "./Features/Admin/ViewDeleteSurvey";
import OfficerDailyReport from "./Features/Admin/OfficerDailyReport";
import NoticeDashboard from "./Features/Notice/NoticeDashboard";

import TSOHome from "./Features/TSO/TSOHome";
import TSOManageSurvey from "./Features/TSO/Survey/TSOManageSurvey";
import TSONoticeDashboard from "./Features/TSO/Notice/TSONoticeDashboard";
import TSOViewSurvey from "./Features/TSO/Survey/TSOViewSurvey";
import TSOGenerateNotice from "./Features/TSO/Notice/TSOGenerateNotice";
import TSODownloadMergePdf from "./Features/TSO/Notice/TSODownloadMergePdf";
import TSOBillBajawaliList from "./Features/TSO/Notice/TSOBillBajawaliList";
import TSOJaptiKarvai from "./Features/TSO/Japti/TSOJaptiKarvai";
import TSOComplianceList from "./Features/TSO/Compliance/TSOComplianceList";
import TSOAddCompliance from "./Features/TSO/Compliance/TSOAddCompliance";
import TSOComplianceHistory from "./Features/TSO/Compliance/TSOComplianceHistory";
import SchoolLetter from "./Features/Admin/SchoolLetter";
import HospitalAddCompliance from "./Features/Hospital/HospitalAddCompliance";
import HospitalComplianceHistory from "./Features/Hospital/HospitalComplianceHistory";
import HospitalDownloadPdf from "./Features/Hospital/HospitalDownloadPdf";
import HospitalGenerateNotice from "./Features/Hospital/HospitalGenerateNotice";
import HospitalComplianceList from "./Features/Hospital/HospitalComplianceList";

function App() {
  const userState = useSelector((state) => state?.surveylogin?.userData?.data);
  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/CitizenLayout" element={<CitizenLayout />}>
            <Route path="/CitizenLayout/formone" element={<Formone />} />
            <Route path="/CitizenLayout/Formtwo" element={<Formtwo />} />
            <Route path="/CitizenLayout/Formthree" element={<Formthree />} />
          </Route>

          {
            userState?.userType_keyname === "admin" && 
            <Route path='/dashboard' element={<CommonLayout />}>
              <Route path='/dashboard/home' element={<Home />} />
              <Route path='/dashboard/manage-users' element={<ManageUsers />} />
              <Route path='/dashboard/mahilawise-report' element={<ManageContractors />} />
              <Route path='/dashboard/fireofficer-report' element={<ManageFireOfficers />} />
              <Route path='/dashboard/mavim-report' element={<ManageMavims />} />
              <Route path='/dashboard/view-single-survey' element={<ViewContractors />} />
              <Route path='/dashboard/manage-surveys' element={<ManageSurveys />} />
              <Route path='/dashboard/manage-re-survey' element={<ManageReSurveys/>} />
              <Route path='/dashboard/view-survey' element={<ViewSurvey />} />
              <Route path='/dashboard/daywise-report' element={<DaywiseReport />} />
              <Route path='/dashboard/verification-report' element={<VerificationReport />} />
              <Route path='/dashboard/nearby-report' element={<NearbyReport />} />
              <Route path='/dashboard/survey-images' element={<SurveyImages />} />
              <Route path='/dashboard/survey-image-verification' element={<SurveyImgVerification />} />
              <Route path='/dashboard/image-verification-report' element={<CrossVerification />} />
              <Route path='/dashboard/rejected-report' element={<RejectionReport />} />
              <Route path='/dashboard/sms-campaign' element={<SmsCampaign />} /> 
              <Route path='/dashboard/promoter-verify' element={<PromoterVerify />} /> 
              <Route path='/dashboard/profile' element={<Profile />} />

              {/* Officer Survey Dashboard */}
              <Route path='/dashboard' element={<OfficerHome />} />
              <Route path="/dashboard/school-letter" element={<SchoolLetter/>}/>
              <Route path='/dashboard/officer-manage-survey' element={<OffiecerManageSurveys />} />
              <Route path='/dashboard/notice-given' element={<NoticeToBeGiven />} /> 
              <Route path='/dashboard/generate-notice' element={<GenerateNotice />} /> 
              <Route path='/dashboard/merge-pdf' element={<DownloadMergePdf />} /> 
              <Route path='/dashboard/notice-generated' element={<NoticeGenerated />} /> 
              <Route path='/dashboard/notice-sent' element={<NoticeSent />} />
              <Route path='/dashboard/officer-view-survey' element={<OfficerViewSurvey />} />
              <Route path='/dashboard/officer-view-notice' element={<ViewNotice />} />
              <Route path='/dashboard/officer-send-notice' element={<SendNoticeToMobile />} />
              <Route path='/dashboard/officer-manage-users' element={<OffiecrManageUsers />} />
              <Route path='/dashboard/officer-report' element={<OfficerReport />} />
              <Route path='/dashboard/officer-wise-report' element={<OfficerWiseReports />} />
              <Route path='/dashboard/bill-bajawali' element={<BillBajawaliList />} />
              <Route path='/dashboard/notice-format' element={<NoticeFormat />} />
              <Route path='/dashboard/notice-dashboard' element={<NoticeDashboard />} />
              <Route path='/dashboard/allocate-target' element={<AllocateTarget />} />
              <Route path='/dashboard/japti-karvai' element={<AllJaptiKarvai />} />

              <Route path='/dashboard/compliance-list' element={<ComplianceList />} />
              <Route path='/dashboard/compliance-history' element={<ComplianceHistory />} />
              <Route path='/dashboard/add-compliance' element={<AddCompliance />} />

              <Route path='/dashboard/delete-history' element={<DeleteSurveyHistory />} />
              <Route path='/dashboard/view-delete-survey' element={<ViewDeleteSurvey />} />
              <Route path='/dashboard/officer-daily-report' element={<OfficerDailyReport />} />

              {/* Hospital Screens */}
              <Route path="/dashboard/hospital-generate-notice" element={<HospitalGenerateNotice />} />
              <Route path="/dashboard/hospital-download-notice" element={<HospitalDownloadPdf />} />
              <Route path="/dashboard/hospital-add-compliance" element={<HospitalAddCompliance />} />
              <Route path="/dashboard/hospital-compliance-list" element={<HospitalComplianceList />} />
              <Route path="/dashboard/hospital-compliance-history" element={<HospitalComplianceHistory />} />

            </Route>
          }

          {
            userState?.userType_keyname === "mavim" &&
            <Route path='/dashboard' element={<CommonLayout />}>
              <Route path='/dashboard' element={<Home2 />} />
              <Route path='/dashboard/manage-users' element={<ManageUsers />} />
              <Route path='/dashboard/manage-surveys' element={<ManageSurveys />} />
              <Route path='/dashboard/view-survey' element={<ViewSurvey />} />
              <Route path='/dashboard/survey-images' element={<SurveyImages />} />
              <Route path='/dashboard/survey-image-verification' element={<SurveyImgVerification />} />
              <Route path='/dashboard/image-verification-report' element={<CrossVerification />} />
              <Route path='/dashboard/rejected-report' element={<RejectionReport />} />
              <Route path='/dashboard/profile' element={<Profile />} />
            </Route>
          }


          {
            userState?.userType_keyname === "dataoperator" ?
              <Route path='/dashboard' element={<CommonLayout />}>
                <Route path='/dashboard' element={<Home2 />} />
                <Route path='/dashboard/survey-images' element={<SurveyImages />} />
                <Route path='/dashboard/view-survey' element={<ViewSurvey />} />
                <Route path='/dashboard/survey-image-verification' element={<SurveyImgVerification />} />
                <Route path='/dashboard/image-verification-report' element={<CrossVerification />} />
                <Route path='/dashboard/profile' element={<Profile />} />
              </Route>
              : ''
          }


          {
            userState?.userType_keyname === "tso" ||  userState?.userType_keyname === "clerk" 
            || userState?.userType_keyname === "fireofficer" ?
              <Route path='/dashboard' element={<CommonLayout />}>
                {/* ----- Home ------ */}
                 <Route path='/dashboard' element={<TSOHome />} />

                 {/* <Route path='/dashboard/tso-manage-survey' element={<TSOManageSurvey />} /> */}
                 <Route path='/dashboard/tso-view-survey' element={<TSOViewSurvey />} />

                 <Route path="/dashboard/school-letter" element={<SchoolLetter/>}/>

                {/* ----- Notice ----*/}
                  <Route path='/dashboard/notice-dashboard' element={<TSONoticeDashboard />} />
                  <Route path='/dashboard/generate-notice' element={<TSOGenerateNotice />} /> 
                  <Route path='/dashboard/merge-pdf' element={<TSODownloadMergePdf />} /> 
                  <Route path='/dashboard/bill-bajawali' element={<TSOBillBajawaliList />} />
                  {/* <Route path='/dashboard/view-survey' element={<OfficerViewSurvey />} /> */}
              
                {/* ----- Japti ----*/}
                    <Route path='/dashboard/japti-karvai' element={<TSOJaptiKarvai />} />

                 {/* ----- Compliance ----*/}
                  <Route path='/dashboard/compliance-list' element={<TSOComplianceList />} />
                  <Route path='/dashboard/compliance-history' element={<TSOComplianceHistory />} />
                  <Route path='/dashboard/add-compliance' element={<TSOAddCompliance />} />
              <Route path='/dashboard/profile' element={<Profile />} />

              
              {/* Hospital Screens */}
              <Route path="/dashboard/hospital-generate-notice" element={<HospitalGenerateNotice />} />
              <Route path="/dashboard/hospital-download-notice" element={<HospitalDownloadPdf />} />
              <Route path="/dashboard/hospital-add-compliance" element={<HospitalAddCompliance />} />
              <Route path="/dashboard/hospital-compliance-list" element={<HospitalComplianceList />} />
              <Route path="/dashboard/hospital-compliance-history" element={<HospitalComplianceHistory />} />

              </Route>
              : ''
          }

          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
