import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Form,
  Button,
  Card,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import Pagination from "../Common/Pagination";
import * as XLSX from "xlsx";
import Skeleton from "../Common/Skeleton";
import moment from "moment";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const DeleteSurveyHistory = () => {
  const userState = useSelector((state) => state.surveylogin.userData.data);
  const navigate = useNavigate();

  const [data, setData] = useState();
  const [loader, setLoader] = useState(false);

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState();

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  useEffect(() => {
    getAllSurveys();
  }, [pageNo, docPerPage]);

  const getAllSurveys = async () => {
    setLoader(true);
    let payload = {
      userId: "",
    };

    await fetch(
      process.env.REACT_APP_BASE_URL + `/newSurvey/getAllDeletedSurvey`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setData(response);
        setNoOfPages(response.noOfPages);
        setLoader(false);
      })
      .catch((err) => {
        console.log("Err while getting surveys", err);
        setLoader(false);
      });
  };

  return (
    <div className="outletPadding allocate-target">

<div className="outer-wrapper mx-auto mt-4">
          {
            <>
              <div
                className="table-wrapper mt-2"
                style={{ maxHeight: "58vh" }}
              >
                <table data-aos="fade-up" data-aos-delay="200">
                  <thead>
                    <th>Sr. No.</th>
                    {/* <th>Property Code</th>
                    <th>Property Name</th> */}
                    <th>Owner Name</th>
                    <th>Owner Mobile</th>
                    <th>Property Type</th>
                    {/* <th>Property Address</th> */}
                    <th>Business Address</th>
                    <th>Action</th>
                  </thead>
                  {loader ? (
                    <Skeleton rows={10} cols={9} />
                  ) : data?.data !== null &&
                    data?.data !== undefined &&
                    data?.data?.length > 0 ? (
                    data?.data?.map((itm, index) => {
                      const {
                        surveyId,
                        propertyCode,
                        propertyName,
                        ownerName,
                        ownerMobile,
                        propertyType,
                        finalBusinessAddress,
                        propertyAddress,
                      } = itm;

                      return (
                        <tr key={Math.random() * 999999999}>
                          <td>
                            {pageNo !== 1 ? (
                              <> {index + 1 + docPerPage * (pageNo - 1)}</>
                            ) : (
                              <>{index + 1}</>
                            )}
                          </td>
                          {/* <td>{propertyCode ? propertyCode : "-"}</td>
                          <td style={{ maxWidth: "15vw" }}>
                            {propertyName ? propertyName : "-"}
                          </td> */}
                          <td style={{ maxWidth: "15vw" }}>
                            {ownerName ? ownerName : "-"}
                          </td>
                          <td>{ownerMobile}</td>
                          <td style={{ maxWidth: "14vw" }}>{propertyType}</td>
                          {/* <td style={{ maxWidth: "18vw" }}>
                            {propertyAddress ? propertyAddress : "-"}
                          </td> */}
                          <td style={{ maxWidth: "20vw" }}>
                            {finalBusinessAddress}
                          </td>
                          <td>
                            <Icon.Eye
                              className="ms-2 icon"
                              size={24}
                              onClick={() =>
                                navigate("/dashboard/view-delete-survey", {
                                  state: surveyId,
                                })
                              }
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <p className="noDataFound">No Data Found</p>
                  )}
                </table>
              </div>

              <Row>
                <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
                  <h6 className="text-center">
                    <Icon.BarChart className="me-2" />
                    Total - <strong>{data?.count}</strong>
                  </h6>
                </Col>
                <Col md={8} className="d-flex justify-content-end">
                  <Pagination
                    currentPage={pageNo}
                    totalPages={noOfPages}
                    onPageChange={handlePageChange}
                    onDocsPerPage={handleDocsPerPage}
                    docsPerPage={docPerPage}
                  />
                </Col>
              </Row>
            </>
          }
        </div>
    </div>
  );
};

export default DeleteSurveyHistory;
