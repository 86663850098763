import React, { useState } from "react";
import { Card} from "react-bootstrap";
import ChartModal from './ChartModal';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {Chart as ChartJS} from 'chart.js/auto';
import { Chart } from "react-chartjs-2";

const MultibarChart = ({ chartLabels, chartData, stacked, chartHeading, height }) => {

  // useEffect(() => {
  //   if (chartData !== undefined && chartData.length > 0) {
  //     setLoader(false);
  //   } else {
  //     setLoader(true);
  //   }
  // }, [chartData]);


  const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const  handleShow = () => setShow(true);

  /* ----- Charts Props for sending ClickEvent Data to Common Modal ------ */
  const [label, setLabel] = useState();
  const [dataLabel, setDataLabel] = useState();
  const [value, setValue] = useState();



  return (
    <>
      <Card style={{ height: height, padding: "30px 20px" }}>
        <h6>{chartHeading}</h6>
          <Chart
            data={{
              labels: chartLabels,
              fill: false,
              datasets:
              chartData?.map((row) => {
                return {
                  type: row?.type,
                  label: row?.label,
                  data: row?.data,
                  fill: true,
                  borderWidth: row?.borderWidth,
                  borderColor:row?.borderColor,
                  backgroundColor: row?.backgroundColor,
                  hoverOffset: 10
                };
              }),
            }}

            plugins={[ChartDataLabels,  {align: "end"}]}
                options={{
                  plugins: {
                    datalabels: {
                      display: true,
                      color: '#808080',
                      formatter: Math.round,
                      anchor: "end",
                      margin:'20px',
                      offset: -20,
                      align: "start",
                      font: {
                            weight: '500',
                            size:'8px'
                          }
                    }
                  },
                  legend: {
                    display: false
                  },
                  interaction: {
                    intersect: false,
                  },
                  scales: {
                    x: {
                      stacked: stacked,
                    },
                    y: {
                      stacked: stacked
                    }
                  },
                  maintainAspectRatio: false,
                  responsive: true,
              onClick: (e, element) => {
                // console.log("Whole E", e);
                // console.log("Element", element);
                // console.log("Label is : ", e.chart.data.labels[element[0].index]);
                // console.log("Index", element[0].index);
                let index = element[0].index;
                // let dataindex = element[0].datasetIndex;

                let arr = [];
                e.chart.config._config.data.datasets.map((row) => {
                  return arr.push(row.data[index]);
                });

                // console.log("Onclick Array", arr);
                // console.log("first", arr[element[0].datasetIndex]);
                // console.log("Data set Array", e.chart.config._config.data.datasets[element[0].datasetIndex].label);


                setLabel(e.chart.data.labels[element[0].index]);
                setDataLabel(e.chart.config._config.data.datasets[element[0].datasetIndex].label);
                setValue(arr[element[0].datasetIndex]);
                setShow(true)

                // if(index!==undefined && index!==""){
                //   console.log("Data set Array", e.chart.config._config.data.datasets[index].label)
                // }
              },
            }}


          />
      </Card>

      <ChartModal open={show} close={() => setShow(false)} data={{ label, dataLabel, value }} />
    </>
  );
};

export default MultibarChart;
