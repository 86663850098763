import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, Spinner} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Pagination from '../Common/Pagination';
import moment from 'moment';
import Chartjs from '../Charts/Chartjs';
import GraphLoader from '../../Assets/GraphLoader.gif';
import Skeleton from '../Common/Skeleton';

const ManageFireOfficers = () => {

    const [data, setData] = useState('');
    const [verificationCount, setVerificationCount] = useState('');
    const [date, setDate] = useState("");
    const [dataCount, setDataCount] = useState("");
    const [name, setName] = useState('')
    const [loader, setLoader] = useState(false);
    const [paginate, setPaginate] = useState(true);
    
    const [searchMobile, setSearchMobile] = useState('');
    const [searchMobileErr, setSearchMobileErr] = useState(false);
    const [dateErr, setDateErr] = useState(false); 

    // States for Pagination ----------
    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(50);
    const [noOfPages, setNoOfPages] = useState();


    // function for pagination
           
      const handlePageChange = (pageNumber) => {
           setPageNo(pageNumber);
       };
       
       const handleDocsPerPage = (docsPageProp) => {
           setPageNo(1)
           setDocPerPage(docsPageProp);
       }

    useEffect(()=>{
        getVerificationCount();
    },[])

    useEffect(()=>{
        getFireOfficers();
    },[pageNo, docPerPage])

    const getFireOfficers = async ()=>{
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/surveys/officerWiseVerifiedSurveyReport', {
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
                verifyDate: date,
                mobile: searchMobile,
                docsPerPage: docPerPage,
                pageNo: pageNo,
                userRole:"fireofficer"
            })
        }).then(res=>res.json()).then((response)=>{
            if(searchMobile.length===10){
                setPaginate(false);
            }
            setData(response?.data);
            setNoOfPages(response?.data?.noOfPages);
            setLoader(false);

            if(response.status === 200){
                let dataArray = [];

                dataArray.push({
                    type: 'bar',
                    label: "Approved",
                    fill: false,
                    data: response?.graph?.verifiedCount,
                    borderWidth: 2,
                    borderColor: 'rgba(0, 100, 0, 1)', 
                    backgroundColor: 'rgba(0, 128, 0, 0.43)'
                    // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
                });

                dataArray.push({
                    type: 'bar',
                    label: "Rejected",
                    fill: false,
                    data: response?.graph?.notVerifiedCount,
                    borderWidth: 2,
                    borderColor: 'rgba(139, 0, 0, 1)', 
                    backgroundColor: 'rgba(255, 0, 0, 0.43)'
                    // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
                });
                setDataCount(dataArray);
            }
            setName(response?.graph?.name);
        }).catch((Err)=>{
            console.log("Err while getting contractors", Err);
            setLoader(false);
        })
    }

    const getVerificationCount = async()=>{
        await fetch(process.env.REACT_APP_BASE_URL + '/surveys/getVerificationCount',{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({ verifyDate: date, mobile: searchMobile, userRole:"fireofficer" })
        }).then((res)=>res.json()).then((response)=>{
            setVerificationCount(response?.data);
        }).catch((Err)=>{
            console.log("Err while getting verification count", Err);
        })
    }

    
    // --------------- Search User By Mobile ------------------------------
    const searchByValidate =()=>{
        let isValid = true;
        if(!searchMobile || searchMobile.length<10){
            isValid= false ;
            setSearchMobileErr(true);
        }
        return isValid;
    }

    const searchByMobile = async()=>{
        if(searchByValidate()){
            getFireOfficers();
            getVerificationCount();
        }
    }


    // -----------------Serach user by Date--------------------------------
    const searchByDateValidate =()=>{
        let isValid = true;
        if(!date){
            isValid= false ;
            setDateErr(true);
        }
        return isValid;
    }

    const searchByDate = async()=>{
        if(searchByDateValidate()){
            getFireOfficers();
            getVerificationCount();
        }
    }



  return (
    <div className='ManageFireOfficers outletPadding'>
        <Container>
        <Row className='d-flex justify-content-between'>
            <Col md={3}>
                <Form.Label>Search by mobile</Form.Label>
                  <div className='d-flex'>
                  <Form.Control placeholder='Enter Mobile No.' value={searchMobile} className='me-3'
                    onChange={(e)=>{ 
                      if(e.target.value.match(/^[0-9]+$/) && e.target.value.length <= 10){
                        setSearchMobile(e.target.value)
                      }
                      else if (e.target.value.length === 0){
                        setSearchMobile(e.target.value)
                      }
                  e.target.value.length>0 ? setSearchMobileErr(false): setSearchMobileErr(true); }} />
                  <Button className='primaryBtn' onClick={searchByMobile}>
                      <Icon.Search className='me-2' size={15}/>Search
                  </Button>
                  </div>
              { searchMobileErr ? <p className='errMsg'>Please enter valid mobile no.</p> : '' }
            </Col>

            <Col md={3}>
            <Form.Label>Search by Date</Form.Label>
                <div className='d-flex align-items-center'>
                    <Form.Control placeholder='Search By Date' type='date' max={new Date()} value={date} onChange={(e)=>setDate(moment(e.target.value).format('YYYY-MM-DD'))}/>
                    <Button className='primaryBtn ms-3' onClick={searchByDate}> 
                    <Icon.Search className='me-2'/>Search</Button>
                </div> 
                {dateErr ? <p className='errMsg'>Please select valid date</p> :''}
            </Col>
        </Row>

        <Row className='justify-content-center mt-3'>
            <Col md={12} className='mb-5'>
            {
                dataCount !== null && dataCount !== undefined && dataCount?.length > 0 ?
                  <Chartjs chartLabels={name} chartData={dataCount}  stacked={false}
                    chartHeading="Fire Officer Verification Graph" height='350px' isPlugin={false} isModal={true}/> :
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <img src={GraphLoader} alt="Loader" width={200} />
                      </div>
            }
            </Col>
        </Row>

            <Row className="outer-wrapper mx-auto">
                    <div className="table-wrapper" style={{maxHeight:'72vh'}}>
                        <table>
                            <thead>
                                <th>Sr. No.</th>
                                <th style={{width:'250px'}}>Name</th>
                                <th>Mobile Number</th>
                                <th>Email ID</th>
                                <th style={{width:'170px'}}>Zone</th>
                                <th>Gat</th>
                                <th>Approved Count</th>
                                <th>Rejected Count</th>
                            </thead>
               
                
                { loader ? <Skeleton rows={8} cols={8}/> :
                <>
                <tbody data-aos="fade-up">
                    {
                    data?.users!== null && data.users !== undefined && data?.users.length > 0 ? 
                    data?.users.map((itm, index) => {
                    const { name, mobile, zonename, zoneno, gatname, email, verifiedCount, notVerifiedCount} = itm
                        return (
                            <tr key={Math.random() * 999999999}>
                                <td>{pageNo !== 1 ? (<> {index + 1 + docPerPage * (pageNo - 1)}</>) : <>{index + 1}</>}</td>
                                <td style={{width:'16vw'}}>{name}</td>
                                <td>{mobile}</td>
                                <td style={{width:'200px'}}>{email? email:'-'}</td>
                                <td>{zonename+' - '+zoneno}</td>
                                <td>{gatname.toString()}</td>
                                <td>{verifiedCount}</td>
                                <td>{notVerifiedCount}</td>
                            </tr>
                        )
                    })
                : <p className='noDataFound'>No Data Found</p>
                }
            </tbody>
            <tfoot>
                  <tr>
                    <th>Total</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>{verificationCount?.verifiedCount}</th>
                    <th>{verificationCount?.notVerifiedCount}</th>
                  </tr>
                </tfoot>
            </>
        }
                  </table>
              </div>

        <Row>
            <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
            <h6 className='text-start mb-0'>
                <Icon.People className='me-2'/>Total - <strong>{data?.count}</strong>
            </h6>
            </Col>
            <Col md={8} className='d-flex justify-content-end'>
            { paginate && <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange} 
                onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage}/> }
            </Col>
        </Row>
    
            </Row>
        </Container>
    </div>
  )
}

export default ManageFireOfficers;