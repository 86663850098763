import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Form,
  Button,
  Card,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import JSZip from "jszip";
import * as Icon from "react-bootstrap-icons";
import Pagination from "../Common/Pagination";
import * as XLSX from "xlsx";
import Skeleton from "../Common/Skeleton";
import GraphLoader from "../../Assets/GraphLoader.gif";
import pako from "pako";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";
import useGetBlockNo from "../Common/ReuseAPI/useGetBlockNo";
import useGetZoneName from "../Common/ReuseAPI/useGetZoneName";
import useGetOfficerList from "../Common/ReuseAPI/useGetOfficerList";

const NoticeGenerated = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [checkboxArray, setCheckBoxArray] = useState([]);
  const [surveyArray, setSurveyArray] = useState([]);
  const [zipLoader, setZipLoader] = useState(false);
  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState();

  const [download, setDownload] = useState(false);
  const [loaderArray, setLoaderArray] = useState([]);
  const [showDownload, setShowDownload] = useState(false);
  const [mergeLoader, setMergeLoader] = useState(false);
  const userState = useSelector((state) => state.surveylogin.userData.data);

  // filter states
  const [startdate, setStartDate] = useState("")
  const [enddate, setEndDate] = useState("")
  const [blockNo, setBlockNo] = useState("")
  const [zoneName, setZoneName] = useState("")
  const [officer, setOfficer] = useState("")

  // filter error
  const [blockErr,setBlockErr] = useState(false)
  const [zoneErr, setZoneErr] = useState(false)
  const [officerErr, setOfficerErr]  = useState(false)

  // Reusable API Call
  const { blockNoList } = useGetBlockNo()
  const {zoneNameList } = useGetZoneName()
  const {officerList } = useGetOfficerList()

  useEffect(() => {
    getNoticeToBeGiven();
  }, [docPerPage, pageNo]);

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  const getNoticeToBeGiven = async () => {
    setLoader(true);
    let payload = {
      fromDate: startdate,
      toDate: enddate,
      user_id: officer,
      blockNo: blockNo,
      zonename: zoneName,
      documentsPerPage: docPerPage,
      page: pageNo,
    };

    await fetch(
      process.env.REACT_APP_BASE_URL +
        "/newSurvey/getAllNoticeGeneratedDashboard",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setLoader(false);
        setData(response);
        let arr = [];
        response?.data?.map((row) => {
          arr.push(false);
        });
        setLoaderArray(arr);
        setNoOfPages(response.noOfPages);
      })
      .catch((Err) => {
        console.log("Err while getting propertType", Err);
        setLoader(false);
      });
  };

  const checkBoxChnage = (event, item) => {
    if (event.target.checked) {
      let arr = [...checkboxArray];
      arr.push(item.noticePDF);
      setCheckBoxArray(arr);

      let SurveyArr = [...surveyArray];
      SurveyArr.push(item.surveyId);
      setSurveyArray(SurveyArr);
    } else {
      let arr = [...checkboxArray];
      let getvalue = arr.findIndex((event) => event === item?.noticePDF);
      arr.splice(getvalue, 1);
      setCheckBoxArray(arr);

      let surveyArr = [...surveyArray];
      let survey = surveyArr.findIndex((event) => event === item?.surveyId);
      surveyArr.splice(survey, 1);
      setSurveyArray(surveyArr);
    }
  };

  const downloadExcel = async () => {
    try {
      setShowDownload(false);
      setZipLoader(true);
      const zip = new JSZip();

      // Download each PDF and add it to the zip file
      await Promise.all(
        checkboxArray.map(async (pdfUrl, index) => {
          const response = await fetch(pdfUrl);
          const data = await response.arrayBuffer();

          // Compress the PDF content using pako
          const compressedData = await pako.deflateRaw(data);

          zip.file(`document${index + 1}.pdf`, compressedData);
        })
      );

      // Generate the zip file
      const zipBlob = await zip.generateAsync({ type: "blob" });

      let payload = {
        surveyIds: surveyArray,
        noticeGenerateId: userState.user_id,
        noticeGenerateMobile: userState.mobile,
        noticeGenerateName: userState.name,
        userRole: userState.userType_keyname,
      };

      await fetch(
        process.env.REACT_APP_BASE_URL + "/notice/updateNoticeGenerated",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      )
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 200) {
            setSurveyArray([]);
            getNoticeToBeGiven();
          }
        })
        .catch((error) => toast.error(error));

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(zipBlob);
      link.download = "pdfs.zip";
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Remove the link element
      document.body.removeChild(link);
      setZipLoader(false);
      setCheckBoxArray([]);
      setSurveyArray([]);
    } catch (error) {
      console.error("Error downloading or zipping PDFs:", error);
      setZipLoader(false);
      setShowDownload(true);
    }
  };

  const changeSelectAll = (event) => {
    if (event.target.checked) {
      let arr = [];
      let surveyArr = [];
      data?.data.map((row) => {
        arr.push(row.noticePDF);
        surveyArr.push(row.surveyId);
      });
      setCheckBoxArray(arr);
      setSurveyArray(surveyArr);
    } else {
      setCheckBoxArray([]);
      setSurveyArray([]);
    }
  };

  const downloadNotice = async (data, index) => {
    let arr = [...loaderArray];
    arr[index] = true;
    setLoaderArray(arr);

    let payload = {
      surveyIds: [data.surveyId],
      noticeGenerateId: userState.user_id,
      noticeGenerateMobile: userState.mobile,
      noticeGenerateName: userState.name,
      userRole: userState.userType_keyname,
    };

    await fetch(
      process.env.REACT_APP_BASE_URL + "/notice/updateNoticeGenerated",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setSurveyArray([]);
        }
      })
      .catch((error) => toast.error(error));

    let DownloadPayload = {
      noticeGenerateId: userState.user_id,
      noticeGenerateMobile: userState.mobile,
      noticeGenerateName: userState.name,
      userRole: userState.userType_keyname,
      initials: "ADM",
      surveyId: data.surveyId,
    };

    await fetch(process.env.REACT_APP_BASE_URL + "/notice/createNoticePDF", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(DownloadPayload),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          fetch(response.data)
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const a = document.createElement("a");
              a.href = url;
              a.download = data.surveyId + ".pdf";
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
              let arr = [...loaderArray];
              arr[index] = false;
              setLoaderArray(arr);
              getNoticeToBeGiven();
            })
            .catch((error) => console.error("Error downloading PDF:", error));
        }
      })
      .catch((error) => toast.error(error));
  };

  const closeModal = () => {
    setShowDownload(false);
  };

  const mergePdf = async () => {
    setMergeLoader(true);
    let payload = {
      surveyIds: surveyArray,
      initials: "AMD",
      noticeGenerateId: userState.user_id,
      noticeGenerateMobile: userState.mobile,
      noticeGenerateName: userState.name,
      userRole: userState.userType_keyname,
    };

    await fetch(process.env.REACT_APP_BASE_URL + "/notice/mergePDF", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log("uasvdv", response);
        if (response.url) {
          fetch(response.url)
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const a = document.createElement("a");
              a.href = url;
              a.download = "Merge.pdf";
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
              setCheckBoxArray([]);
              setSurveyArray([]);
              setMergeLoader(false);
            })
            .catch((error) => {
              console.error("Error downloading PDF:", error);
              setMergeLoader(false);
            });
        } else {
          toast.error("PDF Download API Error");
          setMergeLoader(false);
        }
      });
  };

  // Search by Date function
  const searchByDate = async () => {
    if (startdate && enddate) {
      getNoticeToBeGiven();
    } else {
      !startdate && toast.error("Please Select From Date");
      !enddate && toast.error("Please Select To Date");
    }
  };

  // Search by block and zone
  const searchByBlockZoneValidate = () => {
    let isValid = true;
     if(!zoneName){
      isValid = false;
      setZoneErr(true);
    }else if (!blockNo) {
      isValid = false;
      setBlockErr(true);
    }
    return isValid;
  };

  const searchByBlockZone = async () => {
    if (searchByBlockZoneValidate()) {
     await getNoticeToBeGiven();
    }
  };

  // Search by officer
  const searchByOfficerValidate = () => {
    let isValid = true;
    if (!officer) {
      isValid = false;
      setOfficerErr(true);
    }
    return isValid;
  };

  const searchByOfficer = async () => {
    if (searchByOfficerValidate()) {
     await getNoticeToBeGiven();
    }
  };

  return (
    <div className="outletPadding">
      <ToastContainer />
      {zipLoader ? (
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <img src={GraphLoader} alt="Loader" width={200} />
          <h5>Generating Zip file ...</h5>
        </div>
      ) : (
        <Container>
          <Row className="justify-content-end">
            <Col md={2}>
              <Button
                disabled={checkboxArray.length === 0}
                className="primaryBtn"
                onClick={() => setShowDownload(true)}
              >
                Download PDF's
              </Button>
            </Col>
          </Row>
          <h3>Notice Generated List</h3>

        <Row md={12}>
          <Col md={6} className="mt-3">
            <Card className="searchCard">
              <Form.Label className="searchLabel">Search by Dates</Form.Label>{" "}
              <div className="d-flex align-items-end">
                <Col className="m-2 d-flex flex-column">
                  <Form.Label>From Date</Form.Label>
                  <Form.Control
                    placeholder="Start Date"
                    type="date"
                    value={startdate}
                    onChange={(e) =>
                      setStartDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </Col>
                <Col className="m-2 d-flex flex-column">
                  <Form.Label>To Date</Form.Label>
                  <Form.Control
                    placeholder="End Date"
                    type="date"
                    min={startdate}
                    value={enddate}
                    onChange={(e) =>
                      setEndDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </Col>
                <Col className="m-2 d-flex ">
                  {" "}
                  <Button
                    className="primaryBtn p-2 mt-1"
                    onClick={searchByDate}
                  >
                    <Icon.Search size={18} className="me-2" />
                    Search
                  </Button>
                </Col>
              </div>
            </Card>
          </Col>

          <Col md={6} className="mt-3">
            <Card className="searchCard">
              <Form.Label className="searchLabel">Search by Block</Form.Label>{" "}
                <div className="d-flex align-items-start">
                <Col className="m-2 d-flex flex-column">
                    <Form.Label>Zone Name</Form.Label>
                    <Form.Select
                      value={zoneName}
                      onChange={(e) => {
                        setZoneName(e.target.value);
                        setBlockNo("")
                        e.target.value.length > 0 && setZoneErr(false);
                      }}>
                      <option value="" selected disabled>
                        Select Zone
                      </option>
                      {zoneNameList !== null && zoneNameList !== undefined && zoneNameList.length > 0 ? (
                        zoneNameList?.map((itm, index) => {
                          return (
                            <option key={index} value={itm?.zonename}>
                              {itm?.zonename}
                            </option>
                          );
                        })
                      ) : ( 
                        <option value="">No Data Found</option>
                      )}
                    </Form.Select>
                      {zoneErr ? (
                        <p className="errMsg">Please select zone</p>
                      ) : (
                        ""
                      )}
                  </Col>

                  <Col className="m-2 d-flex flex-column">
                    <Form.Label>Block</Form.Label>
                    <Form.Select
                      disabled={!zoneName}
                      value={blockNo}
                      onChange={(e) => {
                        setBlockNo(e.target.value);
                        e.target.value.length > 0 && setBlockErr(false);
                      }}>
                      <option value="" selected disabled>
                        Select Block
                      </option>
                      {blockNoList !== null && blockNoList !== undefined && blockNoList.length > 0 ? (
                        blockNoList?.map((itm, index) => {
                          return (
                            <option key={index} value={itm?.blockNo}>
                              {itm?.blockNo}
                            </option>
                          );
                        })
                      ) : (
                        <option value="">No Data Found</option>
                      )}
                    </Form.Select>
                      {blockErr ? (
                        <p className="errMsg">Please select block</p>
                      ) : (
                        ""
                      )}
                  </Col>
                  
                  <Col className="m-2 d-flex align-items-end">
                    <Button
                      className="primaryBtn p-2 mt-4"
                      onClick={searchByBlockZone}
                    >
                      <Icon.Search size={18} className="me-2" />
                      Search
                    </Button>
                  </Col>
                </div>
            </Card>
          </Col>
        </Row>
          
        <Row className="justify-content-between mt-3">
          <Col md={4}>
            <Card className="searchCard">
              <Form.Label className="searchLabel">
                Search by Officer
              </Form.Label>
              <Form.Select
                      value={officer}
                      onChange={(e) => {
                        setOfficer(e.target.value);
                        e.target.value.length > 0 && setOfficerErr(false);
                      }}>
                      <option value="" selected disabled>
                        Select Officer
                      </option>
                      {officerList !== null && officerList !== undefined && officerList.length > 0 ? (
                        officerList?.map((itm, index) => {
                          return (
                            <option key={index} value={itm?.user_id}>
                              {itm?.name + " - " + itm?.mobile}
                            </option>
                          );
                        })
                      ) : (
                        <option value="">No Data Found</option>
                      )}
              </Form.Select>
              {officerErr ? ( <p className="errMsg">Please select officer</p>) : ("")}
              <Button
                className="primaryBtn mx-auto mt-2"
                onClick={searchByOfficer}>
              <Icon.Search className="me-2" size={15} />
                Search
              </Button>
            </Card>
          </Col>
        </Row>
        

          <div className="outer-wrapper mx-auto mt-4">
            {
              <>
                <Form.Check
                  label={"Select All"}
                  onChange={(e) => changeSelectAll(e)}
                />
                <div
                  className="table-wrapper mt-2"
                  style={{ maxHeight: download ? "45vh" : "58vh" }}
                >
                  <table data-aos="fade-up" data-aos-delay="200">
                    <thead>
                      <th>Sr. No.</th>
                      <th>Rented Person Name</th>
                      <th>Rented Person Mobile</th>
                      <th>Rented Person Adddress</th>
                      <th>Notice Generate Name</th>
                      <th>Notice Generate Mobile</th>
                      <th>Notice Generate Role</th>
                      <th>Notice Generate Date</th>
                      <th>Action</th>
                    </thead>
                    {loader ? (
                      <Skeleton rows={10} cols={9} />
                    ) : data?.data !== null &&
                      data?.data !== undefined &&
                      data?.data?.length > 0 ? (
                      data?.data?.map((itm, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {pageNo !== 1 ? (
                                <div className="d-flex">
                                  {index + 1 + docPerPage * (pageNo - 1)}
                                  <Form.Check
                                    style={{ marginLeft: 5 }}
                                    name="group1"
                                    type={"checkbox"}
                                    checked={
                                      surveyArray.filter(
                                        (row) => row === itm.surveyId
                                      ).length > 0
                                    }
                                    onChange={(e) => checkBoxChnage(e, itm)}
                                  />
                                </div>
                              ) : (
                                <div className="d-flex">
                                  {index + 1}
                                  <Form.Check
                                    style={{ marginLeft: 5 }}
                                    name="group1"
                                    type={"checkbox"}
                                    checked={
                                      surveyArray.filter(
                                        (row) => row === itm.surveyId
                                      ).length > 0
                                    }
                                    onChange={(e) => checkBoxChnage(e, itm)}
                                  />
                                </div>
                              )}
                            </td>
                            <td>
                              {itm?.rentedPersonName
                                ? itm?.rentedPersonName
                                : "-"}
                            </td>
                            <td>
                              {itm?.rentedPersonMobile
                                ? itm?.rentedPersonMobile
                                : "-"}
                            </td>
                            <td>
                              {itm?.finalBusinessAddress
                                ? itm?.finalBusinessAddress
                                : "-"}
                            </td>
                            <td>
                              {itm?.noticeGenerateName
                                ? itm?.noticeGenerateName
                                : "-"}
                            </td>
                            <td>
                              {itm?.noticeGenerateMobile
                                ? itm?.noticeGenerateMobile
                                : "-"}
                            </td>
                            <td>
                              {itm?.noticeGenerateRole
                                ? itm?.noticeGenerateRole.replace(
                                    /^./,
                                    itm?.noticeGenerateRole[0].toUpperCase()
                                  )
                                : "-"}
                            </td>
                            <td>
                              {itm?.noticeGenerateDate
                                ? moment(itm?.noticeGenerateDate).format(
                                    "DD-MM-YYYY"
                                  )
                                : "-"}
                            </td>
                            <td>
                              {loaderArray[index] ? (
                                <Spinner />
                              ) : (
                                <Button
                                  className="primaryBtn"
                                  onClick={() => downloadNotice(itm, index)}
                                >
                                  Download Notice
                                </Button>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <p className="noDataFound">No Data Found</p>
                    )}
                  </table>
                </div>

                <Row>
                  <Col
                    md={4}
                    className="d-flex align-items-center mt-3 mt-md-0"
                  >
                    <h6 className="text-center">
                      <Icon.BarChart className="me-2" />
                      Total - <strong>{data?.count}</strong>
                    </h6>
                  </Col>
                  <Col md={8} className="d-flex justify-content-end">
                    <Pagination
                      currentPage={pageNo}
                      totalPages={noOfPages}
                      onPageChange={handlePageChange}
                      onDocsPerPage={handleDocsPerPage}
                      docsPerPage={docPerPage}
                    />
                  </Col>
                </Row>
              </>
            }
          </div>
        </Container>
      )}

      <Modal
        size="md"
        show={showDownload}
        backdrop="static"
        keyboard={false}
        centered
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="mb-0">PDF Download</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>How do you want to download pdfs ?</h5>
        </Modal.Body>

        <Modal.Footer>
          {mergeLoader ? (
            <Spinner />
          ) : (
            <>
              <Button className="primaryBtn" onClick={() => downloadExcel()}>
                Single Download in Folder
              </Button>
              <Button className="primaryBtn" onClick={() => mergePdf()}>
                Merge Pdfs
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NoticeGenerated;
