import S3 from "react-aws-s3";
import imageCompression from "browser-image-compression";
window.Buffer = window.Buffer || require("buffer").Buffer;

const ReactS3 = async (file, credentials, directory) => {
  // console.log("file", "type", file.type, credentials, directory);
  try {
    let S3Link;
    //----- Config ---------------------------------
    const config = {
      bucketName: credentials?.bucketName,
      dirName: directory,
      region: credentials?.region,
      accessKeyId: credentials?.accessKeyId,
      secretAccessKey: credentials?.secretAccessKey,
      s3Url: credentials?.s3Url,
    };

    //---- Setting up aws S3 Client -----------
    const ReactS3Client = new S3(config);

    if (file?.type === "video/mp4") {
      console.log(file);
      let size = 3;
      let kb = 1024;
      let mb = kb * 1024;
      if (size < mb * 3) {
        let getTimeStamp = new Date().getTime();
        let newFileName = getTimeStamp + "_." + file.name.split(".")[1];

        await ReactS3Client.uploadFile(file, newFileName)
          .then((res) => {
            if (res.status === 204) {
              S3Link = res?.location;
              alert("Video Uploded successfully");
            } else {
              console.log("AWS Error");
            }
          })
          .catch((err) => {
            console.error("Err while uploading img on S3", err);
          });
      } else {
        alert("Video size less than 3 mb");
      }
    } else {
      // console.log('originalFile instanceof Blob', file instanceof Blob);
      // console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(file, options);
      let getTimeStamp = new Date().getTime();
      let newFileName = getTimeStamp + "_." + file.name.split(".")[1];

      await ReactS3Client.uploadFile(compressedFile, newFileName)
        .then((res) => {
          if (res.status === 204) {
            S3Link = res?.location;
          } else {
            console.log("AWS Error");
          }
        })
        .catch((err) => {
          console.error("Err while uploading img on S3", err);
        });
    }
    return S3Link;
  } catch (error) {
    throw error;
  }
};

export default ReactS3;
