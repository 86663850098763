import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Form,
  Button,
  Card,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import Pagination from "../Common/Pagination";
import * as XLSX from "xlsx";
import Skeleton from "../Common/Skeleton";
import moment from "moment";
import { toast } from "react-toastify";
import FullyChecked from "../../Assets/FullySubmitChecked.svg";
import Surveys from "../../Assets/Surveys.svg";
import Plus from "../../Assets/Plus.svg";
import EqualTo from "../../Assets/EqualTo.svg";

const OfficerWiseReports = () => {
  // navigate , location variables
  const navigate = useNavigate();
  const location = useLocation();
  const officer = location.state.officer;

  //   State Variables
  const [data, setData] = useState();
  const [loader, setLoader] = useState(false);
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState();

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  useEffect(() => {
    getOfficerSurveys();
  }, [pageNo, docPerPage]);

  const getOfficerSurveys = async () => {
    setLoader(true);
    let payload = {
      mobile: officer.mobile,
      documentsPerPage: docPerPage,
      pageNo: pageNo,
      fromDate: startdate,
      toDate: enddate,
    };
    await fetch(process.env.REACT_APP_BASE_URL + `/newSurvey/officerSurvey`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        setData(response);
        setNoOfPages(response.noOfPages);
        setLoader(false);
      })
      .catch((err) => {
        console.log("Err while getting surveys", err);
        setLoader(false);
      });
  };

  const searchByDate = async () => {
    if (startdate && enddate) {
      getOfficerSurveys();
    } else {
      !startdate && toast.error("Please Select From Date");
      !enddate && toast.error("Please Select To Date");
    }
  };

  return (
    <div>
      <Container className="Home outletPadding">
        <Button className="primaryBtn" onClick={() => navigate(-1)}>
          <Icon.ArrowLeft className="me-2" />
          Back
        </Button>

        <Row className="justify-content-center mb-3">
          <Col md={5}>
            <Card className="searchCard mt-4">
              <Form.Label className="searchLabel">Officer Details</Form.Label>
              <div className="d-flex flex-column align-items-start">
                <h5>
                  Name:- <strong>{officer.name}</strong>
                </h5>
                <h5>
                  Mobile:- <strong>{officer.mobile}</strong>
                </h5>
              </div>
            </Card>
          </Col>
        </Row>
        <h6 className="headLabel">General Information Stats</h6>
        <Row className="d-flex justify-content-center">
          <Col
            md={5}
            lg={3}
            data-aos="fade-up"
            data-aos-delay="300"
            className="mb-4 d-flex flex-column flex-md-row align-items-center"
          >
            <Card className="w-100">
              <Card.Body>
                <img src={FullyChecked} alt="Fully Checked" />
                <div className="data">
                  <h4>{officer?.page1SubmitCount}</h4>
                  <h6>Page 1 Submit count</h6>
                </div>
              </Card.Body>
            </Card>
            {/* <img
              src={Plus}
              alt="Plus"
              width={25}
              className="ms-md-3 mt-3 mt-md-0"
            /> */}
          </Col>

          <Col
            md={5}
            lg={3}
            data-aos="fade-up"
            data-aos-delay="400"
            className="mb-4 d-flex flex-column flex-md-row align-items-center"
          >
            <Card className="h-100 w-100">
              <Card.Body>
                <img src={FullyChecked} alt="Withouy Property Code" />
                <div className="data">
                  <h4>{officer?.page2SubmitCount}</h4>
                  <h6>Page 2 Submit count</h6>
                </div>
              </Card.Body>
            </Card>
            {/* <img
              src={EqualTo}
              alt="EqualTo"
              width={25}
              className="ms-md-3 mt-3 mt-md-0"
            /> */}
          </Col>

          <Col
            md={5}
            lg={3}
            data-aos="fade-up"
            data-aos-delay="400"
            className="mb-4 d-flex flex-column flex-md-row align-items-center"
          >
            <Card className="h-100 w-100">
              <Card.Body>
                <img src={FullyChecked} alt="Withouy Property Code" />
                <div className="data">
                  <h4>{officer?.page3SubmitCount}</h4>
                  <h6>Page 3 Submit count</h6>
                </div>
              </Card.Body>
            </Card>
            {/* <img
              src={EqualTo}
              alt="EqualTo"
              width={25}
              className="ms-md-3 mt-3 mt-md-0"
            /> */}
          </Col>

          {/* <Col
            md={5}
            lg={3}
            data-aos="fade-up"
            data-aos-delay="400"
            className="mb-4 pe-0 pe-md-5"
          >
            <Card>
              <Card.Body>
                <img src={Surveys} alt="Fully Submitted" />
                <div className="data">
                  <h4>{ officer?.page1SubmitCount + officer?.page2SubmitCount + officer?.page3SubmitCount}</h4>
                  <h6>Total Submitted Count</h6>
                </div>
              </Card.Body>
            </Card>
          </Col> */}
        </Row>

        <h6 className="headLabel">Fully Submited Reports</h6>

        <Row className="d-flex justify-content-center">
          <Col md={6} className="mt-3">
            <Card className="searchCard">
              <Form.Label className="searchLabel">Search by Dates</Form.Label>{" "}
              <div className="d-flex align-items-end">
                <Col className="m-2 d-flex flex-column">
                  <Form.Label>From Date</Form.Label>
                  <Form.Control
                    placeholder="Start Date"
                    type="date"
                    value={startdate}
                    onChange={(e) =>
                      setStartDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </Col>
                <Col className="m-2 d-flex flex-column">
                  <Form.Label>To Date</Form.Label>
                  <Form.Control
                    placeholder="End Date"
                    type="date"
                    min={startdate}
                    value={enddate}
                    onChange={(e) =>
                      setEndDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </Col>
                <Col className="m-2 d-flex ">
                  {" "}
                  <Button
                    className="primaryBtn p-2 mt-1"
                    onClick={searchByDate}
                  >
                    <Icon.Search size={18} className="me-2" />
                    Search
                  </Button>
                </Col>
              </div>
            </Card>
          </Col>
        </Row>

        <div className="outer-wrapper mx-auto mt-4">
          {
            <>
              <div className="table-wrapper mt-2" style={{ maxHeight: "58vh" }}>
                <table data-aos="fade-up" data-aos-delay="200">
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      {/* <th>Property Code</th>
                    <th>Property Name</th> */}
                      <th>Owner Name</th>
                      <th>Owner Mobile</th>
                      <th>Property Type</th>
                      <th>Sub Property Type</th>
                      {/* <th>Property Address</th> */}
                      <th>Business Address</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loader ? (
                      <Skeleton
                        rows={10}
                        cols={9}
                        key={Math.random() * 999999999}
                      />
                    ) : data?.data !== null &&
                      data?.data !== undefined &&
                      data?.data?.length > 0 ? (
                      data?.data?.map((itm, index) => {
                        const {
                          surveyId,
                          propertyCode,
                          propertyName,
                          ownerName,
                          ownerMobile,
                          propertyType,
                          finalBusinessAddress,
                          propertyAddress,
                          subPropertyType,
                        } = itm;

                        return (
                          <tr key={index}>
                            <td>
                              {pageNo !== 1 ? (
                                <> {index + 1 + docPerPage * (pageNo - 1)}</>
                              ) : (
                                <>{index + 1}</>
                              )}
                            </td>
                            {/* <td>{propertyCode ? propertyCode : "-"}</td>
                          <td style={{ maxWidth: "15vw" }}>
                            {propertyName ? propertyName : "-"}
                          </td> */}
                            <td style={{ maxWidth: "15vw" }}>
                              {ownerName ? ownerName : "-"}
                            </td>
                            <td>{ownerMobile ? ownerMobile : '-'}</td>
                            <td style={{ maxWidth: "14vw" }}>{propertyType ? propertyType : '-'}</td>
                            <td style={{ maxWidth: "14vw" }}>
                              {subPropertyType ? subPropertyType : '-'}
                            </td>
                            {/* <td style={{ maxWidth: "18vw" }}>
                            {propertyAddress ? propertyAddress : "-"}
                          </td> */}
                            <td style={{ maxWidth: "20vw" }}>
                              {finalBusinessAddress ? finalBusinessAddress : '-'}
                            </td>
                            <td>
                              <Icon.Eye
                                className="ms-2 icon"
                                size={24}
                                onClick={() =>
                                  navigate("/dashboard/officer-view-survey", {
                                    state: surveyId,
                                  })
                                }
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <p className="noDataFound">No Data Found</p>
                    )}
                  </tbody>
                </table>
              </div>

              <Row>
                <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
                  <h6 className="text-center">
                    <Icon.BarChart className="me-2" />
                    Total - <strong>{data?.count}</strong>
                  </h6>
                </Col>
                <Col md={8} className="d-flex justify-content-end">
                  <Pagination
                    currentPage={pageNo}
                    totalPages={noOfPages}
                    onPageChange={handlePageChange}
                    onDocsPerPage={handleDocsPerPage}
                    docsPerPage={docPerPage}
                  />
                </Col>
              </Row>
            </>
          }
        </div>
      </Container>
    </div>
  );
};

export default OfficerWiseReports;
