import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Form,
  Button,
  Card,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import Pagination from "../Common/Pagination";
import * as XLSX from "xlsx";
import Skeleton from "../Common/Skeleton";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import useGetBlockNo from "../Common/ReuseAPI/useGetBlockNo";
import useGetZoneName from "../Common/ReuseAPI/useGetZoneName";
import useGetOfficerList from "../Common/ReuseAPI/useGetOfficerList";

const HospitalDownloadPdf = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState();
  const [loaderArray, setLoaderArray] = useState([]);


  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  useEffect(() => {
    setTimeout(() => {
      getMergePDFsData();
    }, [3000]);
  }, []);

  useEffect(() => {
    getMergePDFsData();
  }, [docPerPage, pageNo]);

  const getMergePDFsData = async () => {
    setLoader(true);
    let payload = {
      noticeGnrtPrsnMobile: "",
      surveyZoneKey: "",
      documentsPerPage: docPerPage,
      page: pageNo,
    };

    await fetch(
      process.env.REACT_APP_BASE_URL + "/hospital/getHospitalMergePDFs",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setData(response);
          let arr = [];
          response?.data?.map((row) => {
            arr.push(false);
          });
          setLoaderArray(arr);
          setNoOfPages(response.noOfPages);
          setLoader(false);
        }else{
          setData([])
          setLoader(false)
        }
      })
      .catch((Err) => {
        console.log("Err while getting propertType", Err);
        setLoader(false);
      });
  };

  const downloadNotice = (notice, index) => {
    let arr = [...loaderArray];
    arr[index] = true;
    setLoaderArray(arr);

    fetch(notice.mergePDF)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `Merge_${notice.mergeId}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        let arr = [...loaderArray];
        arr[index] = false;
        setLoaderArray(arr);
      });
  };

  return (
    <div className="outletPadding">
      <ToastContainer />
      <Container>
        <div className="outer-wrapper mx-auto mt-4">
          {
            <>
              <div className="table-wrapper mt-2" style={{ maxHeight: "58vh" }}>
                <table data-aos="fade-up" data-aos-delay="200">
                  <thead>
                    <th>Sr. No.</th>
                    <th>Generate Person Name</th>
                    <th>Generate Person Mobile</th>
                    <th>Notice Generete Date</th>
                    <th>Notice Format</th>
                    <th>Notice Number</th>
                    <th>Action</th>
                  </thead>
                  {loader ? (
                    <Skeleton rows={10} cols={9} />
                  ) : data?.data !== null &&
                    data?.data !== undefined &&
                    data?.data?.length > 0 ? (
                    data?.data?.map((itm, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1 + docPerPage * (pageNo - 1)}</td>
                          <td>
                            {itm?.noticeGnrtPrsnName
                              ? itm?.noticeGnrtPrsnName
                              : "-"}
                          </td>
                          <td>
                            {itm?.noticeGnrtPrsnMobile
                              ? itm?.noticeGnrtPrsnMobile
                              : "-"}
                          </td>
                          <td>
                            {itm?.mergeDate
                              ? moment(itm?.mergeDate).format(
                                  "DD-MM-YYYY hh:mm a"
                                )
                              : "-"}
                          </td>
                          <td>
                            {itm?.noticeFormatName
                              ? itm?.noticeFormatName
                              : "-"}
                          </td>
                          <td>
                            {itm?.userInputNoticeNo
                              ? itm?.userInputNoticeNo
                              : "-"}
                          </td>
                          <td className="d-flex align-items-center">
                            {loaderArray[index] ? (
                              <Spinner />
                            ) : (
                              <Button
                                className="primaryBtn ms-2"
                                onClick={() => {
                                  downloadNotice(itm, index);
                                }}
                              >
                                Download Notice
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <p className="noDataFound">No Data Found</p>
                  )}
                </table>
              </div>

              <Row>
                <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
                  <h6 className="text-center">
                    <Icon.BarChart className="me-2" />
                    Total - <strong>{data?.count ?? 0 }</strong>
                  </h6>
                </Col>
                <Col md={8} className="d-flex justify-content-end">
                  <Pagination
                    currentPage={pageNo}
                    totalPages={noOfPages}
                    onPageChange={handlePageChange}
                    onDocsPerPage={handleDocsPerPage}
                    docsPerPage={docPerPage}
                  />
                </Col>
              </Row>
            </>
          }
        </div>
      </Container>
    </div>
  );
};

export default HospitalDownloadPdf;
