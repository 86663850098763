import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Table, Button, Col, Row, Container, Spinner } from "react-bootstrap";
import * as XLSX from "xlsx";

const OfficerDailyReport = () => {
  const [data, setData] = useState();
  const [officerData, setOfficerData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [noticeloader, setNoticeLoader] = useState(false);
  const [numTotalNoticeCols, setNumTotalNoticeCols] = useState(0);
  const [numTodayNoticeCols, setNumTodayNoticeCols] = useState(0);
  const [noticeDropDown, setNoticeDropdown] = useState([]);

  useEffect(() => {
    getNoticeNos();
    getOfficerReport();
  }, []);

  const getNoticeNos = async () => {
    setNoticeLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + "/newNoticeRoute/noticeNos", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => {
        setNoticeDropdown(response.data);
        setNoticeLoader(false);
      })
      .catch((Err) => {
        console.log("Err while getting propertType", Err);
        setNoticeLoader(false);
      });
  };

  const getOfficerReport = async () => {
    setLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL + "/newSurvey/officerDailyReport",
      {
        method: "GET",
        headers: { "content-type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setData(response);
          setOfficerData(response.data);
          setLoader(false);
        } else {
          setData();
          setOfficerData([]);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log("Error", err);
        setLoader(false);
      });
  };

  const handleExport = () => {
    const wsData = [];
    const headerRow1 = [
      "Sr. No",
      "Officer Name",
      "Officer Mobile",
      "Total Survey",
      "Total Notice",
      ...Array(noticeDropDown.length).fill(""),
      "Today's Survey",
      "Today's Notice",
      ...Array(noticeDropDown.length - 1).fill(""),
    ];
    const headerRow2 = [
      "",
      "",
      "",
      "",
      ...Array.from({ length: noticeDropDown.length }, (_, i) => i + 1),
      "Total Notice",
      "",
      ...Array.from({ length: noticeDropDown.length }, (_, i) => i + 1),
      "Total Notice",
    ];
    wsData.push(headerRow1);
    wsData.push(headerRow2);

    officerData.forEach((officer, index) => {
      const totalNoticeCounts = noticeDropDown.map((Type) => {
        const matchingNotice = officer.notices.find(
          (row) => Type.value === row.noticeNo
        );
        return matchingNotice ? matchingNotice.count : 0;
      });

      const todayNoticeCounts = noticeDropDown.map((Type) => {
        const matchingNoticeToday = officer.noticesToday.find(
          (row) => Type.value === row.noticeNo
        );
        return matchingNoticeToday ? matchingNoticeToday.count : 0;
      });

      const row = [
        index + 1,
        officer.name,
        officer.mobile,
        officer.surveys,
        ...totalNoticeCounts,
        officer.totalNotices,
        officer.surveysToday,
        ...todayNoticeCounts,
        officer.totalNoticesToday,
      ];
      wsData.push(row);
    });

    // Add totals row
    const totalsRow = [
      "",
      "",
      "Total of the counts",
      data.totalSurveys,
      ...Array(noticeDropDown.length).fill(""), // Placeholder for total notices columns
      data.totalNotices,
      data.totalSurveysToday,
      ...Array(noticeDropDown.length).fill(""), // Placeholder for today's notices columns
      data.totalNoticesToday,
    ];
    wsData.push(totalsRow);

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Report");

    // Add merges
    ws["!merges"] = [
      { s: { r: 0, c: 4 }, e: { r: 0, c: 3 + noticeDropDown.length + 1 } }, // Merge "Total Notice" columns
      {
        s: { r: 0, c: 6 + noticeDropDown.length },
        e: { r: 0, c: 5 + noticeDropDown.length + noticeDropDown.length + 1 },
      }, // Merge "Today's Notice" columns
    ];

    XLSX.writeFile(wb, `OfficerDailyReport-${moment(new Date()).format("DD-MMM-YYYY")}.xlsx`);
  };

  return (
    <div className="outletPadding">
      <Container>
        <Row className="justify-content-end mt-5">
          {/* <Col md={2} className="d-flex justify-content-end">
                <Button
                  disabled={checkboxArray.length === 0}
                  className="primaryBtn"
                  onClick={() => setShowDownload(true)}
                >
                  Download PDF's
                </Button>
              </Col> */}

          <Col md={2} className="d-flex justify-content-end">
            <Button className="primaryBtn" onClick={() => handleExport()}>
              Generate Excel
            </Button>
          </Col>
        </Row>
        <Row>
          {loader || noticeloader ? (
            <center>
              <Spinner />
            </center>
          ) : (
            <Col md={12}>
              <div className="outer-wrapper report-table mx-auto mt-4">
                {
                  <>
                    <div
                      className="table-wrapper mt-2"
                      style={{ maxHeight: "70vh" }}
                    >
                      <table data-aos="fade-up" data-aos-delay="200">
                        <thead>
                          <tr>
                            <th rowSpan="2">Sr. No</th>
                            <th rowSpan="2">Officer Name</th>
                            <th rowSpan="2">Officer Mobile</th>
                            <th rowSpan="2">Total Survey</th>
                            <th colSpan={noticeDropDown.length + 1}>
                              Total Notice
                            </th>
                            <th rowSpan="2">Today's Survey</th>
                            <th colSpan={noticeDropDown.length + 1}>
                              Today's Notice
                            </th>
                          </tr>
                          <tr>
                            {noticeDropDown.map((_, idx) => (
                              <th key={`totalNotice-${idx}`}>{idx + 1}</th>
                            ))}
                            <th>Total count</th>
                            {noticeDropDown.map((_, idx) => (
                              <th key={`todayNotice-${idx}`}>{idx + 1}</th>
                            ))}
                            <th>Total count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {officerData.map((officer, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{officer.name}</td>
                              <td>{officer.mobile}</td>
                              <td>{officer.surveys}</td>
                              {noticeDropDown.map((Type, index) => (
                                <td key={index}>
                                  {officer.notices.filter(
                                    (row) => Type.value === row.noticeNo
                                  ).length > 0
                                    ? officer.notices.filter(
                                        (row) => Type.value === row.noticeNo
                                      )[0].count
                                    : 0}
                                </td>
                              ))}
                              <td>{officer.totalNotices}</td>
                              <td>{officer.surveysToday}</td>
                              {noticeDropDown.map((Type, index) => (
                                <td key={index}>
                                  {officer.noticesToday.filter(
                                    (row) => Type.value === row.noticeNo
                                  ).length > 0
                                    ? officer.noticesToday.filter(
                                        (row) => Type.value === row.noticeNo
                                      )[0].count
                                    : 0}
                                </td>
                              ))}
                              <td>{officer.totalNoticesToday}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          {data !== undefined && data.data.length > 0 ? (
                            <tr className="sum-row">
                              <td colSpan="3">
                                <strong className="m-2">
                                  Total of the Counts :-
                                </strong>
                              </td>
                              <td>
                                <strong>{data.totalSurveys}</strong>
                              </td>
                              <td colSpan={noticeDropDown.length}></td>
                              <td>
                                <strong>{data.totalNotices}</strong>
                              </td>
                              <td>
                                <strong>{data.totalSurveysToday}</strong>
                              </td>
                              <td colSpan={noticeDropDown.length}></td>

                              <td>
                                <strong>{data.totalNoticesToday}</strong>
                              </td>
                            </tr>
                          ) : null}
                        </tfoot>
                      </table>
                    </div>
                  </>
                }
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default OfficerDailyReport;
