import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  Spinner,
  Table,
  Tab,
  Nav,
  Card,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import Pagination from "../Common/Pagination";
import Skeleton from "../Common/Skeleton";

const AllocateTarget = () => {
  const navigate = useNavigate();
  const userState = useSelector((state) => state?.surveylogin?.userData);
  const [key, setKey] = useState("NotAllocatedOfficers");

  const [notAllocatedata, setNotAllocateData] = useState();
  const [allocatedata, setAllocateData] = useState();
  const [notAllocateloader, setNotAllocateLoader] = useState(false);
  const [allocateloader, setAllocateLoader] = useState(false);
  const [checkboxArray, setCheckBoxArray] = useState([]);
  const [loaderArray, setLoaderArray] = useState([]);
  const [typesArray, setTypesArray] = useState([]);

  // filter states
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [type, setType] = useState("");
  const [targetCount, setTargetCount] = useState("");

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState();

  useEffect(() => {
    getTargetTypes();
  }, []);

  useEffect(() => {
    if(key === "AllocatedOfficers"){
      getTargetsData();

    }
  }, [pageNo, docPerPage]);

  // function for pagination

  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  const getTargetTypes = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/target/getTargetTypes", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        setTypesArray(response.data);
      })
      .catch((Err) => {
        console.log("Err while getting propertType", Err);
      });
  };

  const validateOfficer = () => {
    if (type && startdate && enddate && targetCount) {
      return true;
    } else {
      if (!type) {
        toast.error("Please Select Type");
      }
      if (!startdate) {
        toast.error("Please Select Start Date");
      }
      if (!enddate) {
        toast.error("Please Select End Date");
      }
      if (!targetCount) {
        toast.error("Please Enter Target Count");
      }
      return false;
    }
  };

  const getAllOfficers = async () => {
    if (validateOfficer()) {
      setNotAllocateLoader(true);
      let payload = {
        type: type,
        startDate: startdate,
        endDate: enddate,
        documentsPerPage: docPerPage,
        page: pageNo,
      };

      await fetch(process.env.REACT_APP_BASE_URL + "/target/getTargetData", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((response) => {
          setNotAllocateLoader(false);
          if (response.status === 200) {
            setNotAllocateData(response);
            let arr = [];
            response.data.map((row) => arr.push(false));
            setLoaderArray(arr);
            setNoOfPages(response.noOfPages);
          }
        })
        .catch((Err) => {
          console.log("Err while getting propertType", Err);
          setNotAllocateLoader(false);
        });
    }
  };

  const checkBoxChnage = (event, item) => {
    if (event.target.checked) {
      let arr = [...checkboxArray];
      arr.push(item);
      setCheckBoxArray(arr);
    } else {
      let arr = [...checkboxArray];
      let getvalue = arr.findIndex((row) => row?.user_id === item?.user_id);
      arr.splice(getvalue, 1);
      setCheckBoxArray(arr);
    }
  };

  const changeSelectAll = (event) => {
    if (event.target.checked) {
      let arr = [];
      notAllocatedata?.data.map((row) => {
        arr.push(row);
      });
      setCheckBoxArray(arr);
    } else {
      setCheckBoxArray([]);
    }
  };

  const allocateTargetsData = async () => {
    let IdArray = checkboxArray.map((row) => ({
      userId: row.user_id,
      userName: row.name,
      userMobile: row.mobile,
    }));
    let payload = {
      userIds: IdArray,
      startDate: startdate,
      endDate: enddate,
      targetCount: targetCount,
      type: type,
    };
    await fetch(process.env.REACT_APP_BASE_URL + "/target/addTarget", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setKey("AllocatedOfficers");
          setEndDate("");
          setStartDate("");
          setTargetCount("");
          setType("");
          getTargetsData();
        }
      })
      .catch((Err) => {
        console.log("Err while getting propertType", Err);
        setAllocateLoader(false);
      });
  };

  const getTargetsData = async () => {
    setAllocateLoader(true);
    let payload = {
      type: type,
      startDate: startdate,
      endDate: enddate,
      documentsPerPage: docPerPage,
      page: pageNo,
    };

    await fetch(process.env.REACT_APP_BASE_URL + "/target/getAllTargets", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        setAllocateLoader(false);
        if (response.status === 200) {
          setAllocateData(response);
          setNoOfPages(response.noOfPages);
        }
      })
      .catch((Err) => {
        console.log("Err while getting propertType", Err);
        setAllocateLoader(false);
      });
  };

  const onTabchange = (k) => {
    setKey(k);
    if (k === "NotAllocatedOfficers") {
      setNotAllocateData([]);
    } else {
      setEndDate("");
      setStartDate("");
      setTargetCount("");
      setType("");
      getTargetsData();
    }
  };

  const allocateSingleData = async (user, index) => {
    let arr = [...loaderArray];
    arr[index] = true;
    setLoaderArray(arr);
    let payload = {
      userIds: [
        {
          userId: user.user_id,
          userName: user.name,
          userMobile: user.mobile,
        },
      ],
      startDate: startdate,
      endDate: enddate,
      targetCount: targetCount,
      type: type,
    };

    await fetch(process.env.REACT_APP_BASE_URL + "/target/addTarget", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        setAllocateLoader(false);
        if (response.status === 200) {
          let arr = [...loaderArray];
          arr[index] = false;
          setLoaderArray(arr);
          setKey("AllocatedOfficers");
          getTargetsData();
        }
      })
      .catch((Err) => {
        console.log("Err while getting propertType", Err);
        setAllocateLoader(false);
      });
  };

  return (
    <div className="outletPadding allocate-target">
      <ToastContainer />

      <Container>
        <Row className="justify-content-center mt-2">
          <Col md={10} className="mt-3">
            <Card className="searchCard">
              <Form.Label className="searchLabel">
                Search Not Allocated Officers
              </Form.Label>{" "}
              <div className="date-flex">
                <Col className="m-2 d-flex flex-column">
                  <Form.Label>From Date</Form.Label>
                  <Form.Control
                    placeholder="Start Date"
                    type="date"
                    value={startdate}
                    onChange={(e) =>
                      setStartDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </Col>
                <Col className="m-2 d-flex flex-column">
                  <Form.Label>To Date</Form.Label>
                  <Form.Control
                    placeholder="End Date"
                    type="date"
                    min={startdate}
                    value={enddate}
                    onChange={(e) =>
                      setEndDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </Col>
                <Col className="m-2 d-flex flex-column">
                  <Form.Label>Type Name</Form.Label>
                  <Form.Select
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  >
                    <option value="" selected disabled>
                      Select Type
                    </option>
                    {typesArray !== null &&
                    typesArray !== undefined &&
                    typesArray.length > 0 ? (
                      typesArray?.map((itm, index) => {
                        return (
                          <option key={index} value={itm?.value}>
                            {itm?.label}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No Data Found</option>
                    )}
                  </Form.Select>
                </Col>

                {key === "NotAllocatedOfficers" && (
                  <Col className="m-2 d-flex flex-column">
                    <Form.Label>Target Count</Form.Label>
                    <Form.Control
                      placeholder="Enter Target Count"
                      value={targetCount}
                      onChange={(e) => {
                        if (
                          e.target.value.match(/^[0-9]+$/) &&
                          e.target.value > 0
                        ) {
                          setTargetCount(e.target.value);
                        } else if (e.target.value.length === 0) {
                          setTargetCount(e.target.value);
                        }
                      }}
                    />
                  </Col>
                )}

                <Col className="m-2 d-flex ">
                  {" "}
                  <Button
                    className="primaryBtn p-2 mt-1"
                    onClick={() =>
                      key === "NotAllocatedOfficers"
                        ? getAllOfficers()
                        : getTargetsData()
                    }
                  >
                    <Icon.Search size={18} className="me-2" />
                    Search
                  </Button>
                </Col>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col className="tab">
            <Tab.Container activeKey={key} onSelect={(k) => onTabchange(k)}>
              <Row className="flex-column align-items-center">
                <Col xs={12} className="d-flex justify-content-end">
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item>
                      <Nav.Link eventKey="NotAllocatedOfficers">
                        Not Allocated Officers
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="AllocatedOfficers">
                        Allocated Officers
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>

                <Col className="mt-4">
                  <Tab.Content>
                    <Tab.Pane eventKey="NotAllocatedOfficers">
                      {notAllocatedata?.data &&
                        notAllocatedata.data.length > 0 && (
                          <Row className="justify-content-center mt-2">
                            <Row className="justify-content-end mt-5">
                              <Col
                                md={2}
                                className="d-flex justify-content-end"
                              >
                                <Button
                                  disabled={checkboxArray.length === 0}
                                  className="primaryBtn"
                                  onClick={() => allocateTargetsData()}
                                >
                                  Allocate Target
                                </Button>
                              </Col>
                            </Row>
                            <div className="outer-wrapper mx-auto mt-4">
                              <Form.Check
                                label={"Select All"}
                                onChange={(e) => changeSelectAll(e)}
                              />
                              <div
                                className="table-wrapper"
                                style={{ maxHeight: "70vh" }}
                              >
                                <table>
                                  <thead>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Mobile Number</th>
                                    <th>Email ID</th>
                                    <th>Role</th>
                                    <th>Zone</th>
                                    <th>Gat</th>
                                    <th>Actions</th>
                                  </thead>
                                  {notAllocateloader ? (
                                    <Skeleton rows={8} cols={8} />
                                  ) : notAllocatedata !== null &&
                                    notAllocatedata !== undefined &&
                                    notAllocatedata?.data.length > 0 ? (
                                    notAllocatedata?.data.map((itm, index) => {
                                      const {
                                        user_id,
                                        name,
                                        mobile,
                                        userType_name,
                                        zoneno,
                                        gatname,
                                        email,
                                      } = itm;
                                      return (
                                        <tr key={Math.random() * 999999999}>
                                          <td>
                                            {pageNo !== 1 ? (
                                              <div className="d-flex">
                                                {index +
                                                  1 +
                                                  docPerPage * (pageNo - 1)}
                                                <Form.Check
                                                  style={{ marginLeft: 5 }}
                                                  name="group1"
                                                  type={"checkbox"}
                                                  checked={
                                                    checkboxArray.filter(
                                                      (row) =>
                                                        row.user_id ===
                                                        itm.user_id
                                                    ).length > 0
                                                  }
                                                  onChange={(e) =>
                                                    checkBoxChnage(e, itm)
                                                  }
                                                />
                                              </div>
                                            ) : (
                                              <div className="d-flex">
                                                {index + 1}
                                                <Form.Check
                                                  style={{ marginLeft: 5 }}
                                                  name="group1"
                                                  type={"checkbox"}
                                                  checked={
                                                    checkboxArray.filter(
                                                      (row) =>
                                                        row.user_id ===
                                                        itm.user_id
                                                    ).length > 0
                                                  }
                                                  onChange={(e) =>
                                                    checkBoxChnage(e, itm)
                                                  }
                                                />
                                              </div>
                                            )}
                                          </td>
                                          <td style={{ width: "18vw" }}>
                                            {name}
                                          </td>
                                          <td>{mobile}</td>
                                          <td>{email ? email : "-"}</td>
                                          <td>{userType_name}</td>
                                          <td>{zoneno}</td>
                                          <td>
                                            {gatname.length > 0
                                              ? gatname.toString()
                                              : "-"}
                                          </td>
                                          <td>
                                            {loaderArray[index] ? (
                                              <center>
                                                <Spinner />
                                              </center>
                                            ) : (
                                              <Button
                                                className="primaryBtn ms-2"
                                                disabled={
                                                  checkboxArray.filter(
                                                    (row) =>
                                                      row.user_id ===
                                                      itm.user_id
                                                  ).length > 0
                                                }
                                                onClick={() => {
                                                  allocateSingleData(
                                                    itm,
                                                    index
                                                  );
                                                }}
                                              >
                                                Single Allocate Data
                                              </Button>
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <p className="noDataFound">No Data Found</p>
                                  )}
                                </table>
                              </div>

                              {!notAllocateloader && (
                                <Row>
                                  <Col
                                    md={4}
                                    className="d-flex align-items-center mt-3 mt-md-0"
                                  >
                                    <h6 className="text-start mb-0">
                                      <Icon.People className="me-2" />
                                      Total -{" "}
                                      <strong>{notAllocatedata?.count}</strong>
                                    </h6>
                                  </Col>
                                  <Col
                                    md={8}
                                    className="d-flex justify-content-end"
                                  >
                                    <Pagination
                                      currentPage={pageNo}
                                      totalPages={noOfPages}
                                      onPageChange={handlePageChange}
                                      onDocsPerPage={handleDocsPerPage}
                                      docsPerPage={docPerPage}
                                    />
                                  </Col>
                                </Row>
                              )}
                            </div>
                          </Row>
                        )}
                    </Tab.Pane>

                    <Tab.Pane eventKey="AllocatedOfficers">
                      <Row className="justify-content-center mt-2">
                        <div className="outer-wrapper mx-auto mt-4">
                          <div
                            className="table-wrapper"
                            style={{ maxHeight: "70vh" }}
                          >
                            <table>
                              <thead>
                                <th>Sr. No.</th>
                                <th>Officer Name</th>
                                <th>Officer Mobile</th>
                                <th>Target Type</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Target Count</th>
                              </thead>
                              {allocateloader ? (
                                <Skeleton rows={8} cols={8} />
                              ) : allocatedata !== null &&
                                allocatedata !== undefined &&
                                allocatedata?.data.length > 0 ? (
                                allocatedata?.data.map((itm, index) => {
                                  return (
                                    <tr key={Math.random() * 999999999}>
                                      <td>
                                        {pageNo !== 1 ? (
                                          <div className="d-flex">
                                            {index +
                                              1 +
                                              docPerPage * (pageNo - 1)}
                                          </div>
                                        ) : (
                                          <div className="d-flex">
                                            {index + 1}
                                          </div>
                                        )}
                                      </td>
                                      <td>{itm.userName}</td>
                                      <td>{itm.userMobile}</td>
                                      <td>
                                        {
                                          typesArray.filter(
                                            (row) => row.value === itm.type
                                          )[0].label
                                        }
                                      </td>
                                      <td>
                                        {moment(itm.startDate).format(
                                          "DD-MMM-YYYY"
                                        )}
                                      </td>
                                      <td>
                                        {moment(itm.endDate).format(
                                          "DD-MMM-YYYY"
                                        )}
                                      </td>
                                      <td>{itm.targetCount}</td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <p className="noDataFound">No Data Found</p>
                              )}
                            </table>
                          </div>

                          {!allocateloader && (
                            <Row>
                              <Col
                                md={4}
                                className="d-flex align-items-center mt-3 mt-md-0"
                              >
                                <h6 className="text-start mb-0">
                                  <Icon.People className="me-2" />
                                  Total - <strong>{allocatedata?.count}</strong>
                                </h6>
                              </Col>
                              <Col
                                md={8}
                                className="d-flex justify-content-end"
                              >
                                <Pagination
                                  currentPage={pageNo}
                                  totalPages={noOfPages}
                                  onPageChange={handlePageChange}
                                  onDocsPerPage={handleDocsPerPage}
                                  docsPerPage={docPerPage}
                                />
                              </Col>
                            </Row>
                          )}
                        </div>
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AllocateTarget;
