import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Form,
  Button,
  Card,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import Pagination from "../Common/Pagination";
import * as XLSX from "xlsx";
import Skeleton from "../Common/Skeleton";
import moment from "moment";
import { toast } from "react-toastify";

const OfficerReport = () => {
  const navigate = useNavigate();

  const [data, setData] = useState();
  const [loader, setLoader] = useState(false);
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState();

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  useEffect(() => {
    getAllSurveys();
  }, [pageNo, docPerPage]);

  const getAllSurveys = async () => {
    setLoader(true);
    let payload = {
      fromDate: startdate ? startdate : "",
      toDate: enddate ? enddate : "",
      documentsPerPage: docPerPage,
      pageNo: pageNo,
    };
    await fetch(process.env.REACT_APP_BASE_URL + `/newSurvey/officerReport`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        setData(response);
        setNoOfPages(response.noOfPages);
        setLoader(false);
      })
      .catch((err) => {
        console.log("Err while getting surveys", err);
        setLoader(false);
      });
  };

  const searchByDate = async () => {
    if (startdate && enddate) {
      getAllSurveys();
    } else {
      !startdate && toast.error("Please Select Start Date");
      !enddate && toast.error("Please Select End Date");
    }
  };

  const calculateSum = (field) => {
    if (data?.data && data.data.length > 0) {
      let sum = 0;
      data.data.map((row) => {
        sum += row[field];
      });
      return sum;
    }
  };

  return (
    <div className="ManageSurveys outletPadding">
      <Container>
        {/* <Row>
          <Col md={6} className="d-flex align-items-end">
            {" "}
            <Col className="m-2 d-flex flex-column">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                placeholder="Start Date"
                type="date"
                value={startdate}
                onChange={(e) =>
                  setStartDate(moment(e.target.value).format("YYYY-MM-DD"))
                }
              />

            </Col>
            <Col className="m-2 d-flex flex-column">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                placeholder="End Date"
                type="date"
                value={enddate}
                onChange={(e) =>
                  setEndDate(moment(e.target.value).format("YYYY-MM-DD"))
                }
              />

            </Col>
            <Col className="m-2 d-flex ">
              {" "}

              <Button className="primaryBtn p-2 mt-1" onClick={searchByDate}>
                <Icon.Search size={18} className="me-2" />
                Search
              </Button>
            </Col>
          </Col>
        </Row> */}

        <div className="outer-wrapper mx-auto mt-4">
          {
            <>
              <div className="table-wrapper mt-2" style={{ maxHeight: "60vh" }}>
                <table data-aos="fade-up" data-aos-delay="200">
                  <thead>
                    <th>Sr. No.</th>
                    <th>Officer Name</th>
                    <th>Officer Mobile</th>
                    <th>Page 1 Submit Count</th>
                    <th>Page 2 Submit Count</th>
                    <th>Page 3 Submit Count</th>
                    <th>Total Count</th>
                    <th>Action</th>
                  </thead>
                    <tbody>
                    {loader ? (
                    <Skeleton rows={10} cols={9} />
                  ) : data?.data && data?.data?.length > 0 ? (
                    data?.data?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {pageNo !== 1 ? (
                              <> {index + 1 + docPerPage * (pageNo - 1)}</>
                            ) : (
                              <>{index + 1}</>
                            )}
                          </td>
                          <td>{item?.name ? item?.name : "-"}</td>
                          <td>{item?.mobile ? item?.mobile : "-"}</td>
                          <td>
                            {item?.page1SubmitCount
                              ? item?.page1SubmitCount
                              : "0"}
                          </td>
                          <td>
                            {item?.page2SubmitCount
                              ? item?.page2SubmitCount
                              : "0"}
                          </td>
                          <td>
                            {item?.page3SubmitCount
                              ? item?.page3SubmitCount
                              : "0"}
                          </td>
                          <td>{item?.totalCount ? item?.totalCount : "0"}</td>
                          <td>
                            <Icon.Eye className="ms-2 icon" size={24} onClick={ () => {
                              navigate("/dashboard/officer-wise-report",{
                                state:{
                                  officer:item
                                }
                              })
                            } } />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <p className="noDataFound">No Data Found</p>
                  )}
                    </tbody>
                  <tfoot >
                 {
                  data !== undefined && data.data.length > 0 ?  <tr className="sum-row">
                   <td colSpan="3"><strong className="m-2">Total of the Counts :-</strong></td>
                   <td>
                     <strong>{data.page1SubmitCount}</strong>
                   </td>
                   <td>
                     <strong>{data.page2SubmitCount}</strong>
                   </td>
                   <td>
                     <strong>{data.page3SubmitCount}</strong>
                   </td>
                   <td>
                     <strong>{data.totalCount}</strong>
                   </td>
                 </tr> : null
                 }
                  </tfoot>
                </table>
              </div>

              <Row>
                <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
                  <h6 className="text-center">
                    <Icon.BarChart className="me-2" />
                    Total - <strong>{data?.count}</strong>
                  </h6>
                </Col>
                <Col md={8} className="d-flex justify-content-end">
                  <Pagination
                    currentPage={pageNo}
                    totalPages={noOfPages}
                    onPageChange={handlePageChange}
                    onDocsPerPage={handleDocsPerPage}
                    docsPerPage={docPerPage}
                  />
                </Col>
              </Row>
            </>
          }
        </div>
      </Container>
    </div>
  );
};

export default OfficerReport;
