import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Spinner, Form } from 'react-bootstrap';
import GraphLoader from '../../Assets/GraphLoader.gif';
import Chartjs from '../Charts/Chartjs';
import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import Pagination from '../Common/Pagination';
import Skeleton from '../Common/Skeleton';

const DaywiseReport = () => {

    const navigate = useNavigate();

    const [dates, setDates] = useState('');
    const [averageCount, setAverageCount] = useState('')
    const [counts, setCounts] = useState('');

    const [data, setData] = useState('');
    const [loader, setLoader] = useState(false);

    const [days, setDays] = useState(7);
    
    // States for Pagination ----------
    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(50);
    const [noOfPages, setNoOfPages] = useState('');


    useEffect(()=>{
      getDaywiseReport();
    },[days])

    const getDaywiseReport = async()=>{
          await fetch(process.env.REACT_APP_BASE_URL + `/surveys/dayWiseSurveyReport/${days}`, {
            method: "GET"
          }).then((res)=>res.json()).then((response)=>{
            if (response.status === 200) {
              let dataArray = [];
      
              dataArray.push({
                type: 'bar',
                label: "Survey Count",
                fill: true,
                data:response?.counts,
                borderWidth: 2,
                borderColor: ['rgb(82, 163, 255)'],
                backgroundColor: ["rgb(194, 222, 255)"]
      
              });
              setCounts(dataArray);
            }
            setDates(response?.dates);
            setAverageCount(response?.average)
          }).catch((Err)=>{
            console.log('Err while getting daywise report', Err);
          })
    }

      const [clickedBarData, setClickedBarData] = useState(
        {
          label: new Date().toISOString().split('T')[0],
          dataLabel: "",
          value: ""
      });


      useEffect(()=>{
        getContractors();
    },[pageNo, docPerPage])

    useEffect(()=>{
      setPageNo(1);
      setDocPerPage(50);
      getContractors();
    },[clickedBarData])

     // function for pagination
           
     const handlePageChange = (pageNumber) => {
      setPageNo(pageNumber);
  };
  
  const handleDocsPerPage = (docsPageProp) => {
      setPageNo(1)
      setDocPerPage(docsPageProp);
  }

    const getContractors = async ()=>{
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/surveys/contractorWiseSurveyReport', {
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
                surveyDate: clickedBarData?.label,
                docsPerPage: docPerPage,
                pageNo: pageNo
            })
        }).then(res=>res.json()).then((response)=>{
            setData(response?.data);
            setNoOfPages(response?.data?.noOfPages);
            setLoader(false);
        }).catch((Err)=>{
            console.log("Err while getting contractors", Err);
            setLoader(false);
        })
    }


  return (
    <div className='WeeklyReport outletPadding'>
        <Container>

          <Row className='justify-content-end mb-3'>
            <Col md={2}>
                <Form.Group>
                  <Form.Label>Filter By Days</Form.Label> 
                  <div className='d-flex align-items-center'>
                      <Form.Select value={days} onChange={(e)=>setDays(e.target.value)}>
                        <option value='7' selected>1 Week (7 Days)</option>
                        <option value='14'> 2 Weeeks (14 Days)</option>
                        <option value='28'>4 Weeks (28 Days)</option>
                        <option value='35'>5 Weeeks (35 Days)</option>
                      </Form.Select>
                   </div>
                </Form.Group>
              </Col>
          </Row>

            <Row className='justify-content-center'>
              <Col md={12} className='mb-5'>
              {
                counts !== null && counts !== undefined && counts?.length > 0 ?
                  <Chartjs chartLabels={dates} chartData={counts}  stacked={false}
                    chartHeading="Weekly Survey Graph" height='300px' isPlugin={false} 
                     onBarClick={(dataValue)=>setClickedBarData(dataValue)} isModal={false}/> :
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <img src={GraphLoader} alt="Loader" width={200} />
                      </div>
              }
              </Col>
            </Row>

            <Row className='justify-content-center'>
            <Col md={4} className='mb-1'>
              <h5 className='text-center'><Icon.ArrowDownCircleFill className='me-2'/>
                Survey Report of <strong>{clickedBarData?.label}</strong></h5>
              </Col>
              <Col md={3}>
                <h5 className='text-center'><Icon.CalculatorFill className='me-2'/>
                Average Count : <strong>{averageCount}</strong></h5>
              </Col>
            </Row>

            <div className="outer-wrapper mx-auto mt-0">
              <div className="table-wrapper" style={{maxHeight:'72vh'}} 
                  data-aos="fade-up" data-aos-delay="100">
                    <table data-aos="fade-up" data-aos-delay="200">
                      <thead>
                        <th>Sr. No.</th>
                        <th>Name</th>
                        <th>Mobile Number</th>
                        <th>Zone</th>
                        <th>Gat</th>
                        <th>Only<br/> Searched</th>
                        <th>Partly<br/>Submitted</th>
                        <th>Fully<br/> Submitted</th>
                        <th>Actions</th>
                      </thead>

                    { loader ? <Skeleton rows={8} cols={9}/> :
                      <tbody data-aos="fade-up">
                    {
                      data?.users!== null && data.users !== undefined && data?.users.length > 0 ? 
                       data?.users.map((itm, index) => {
                       const { name, mobile, zoneno, gatname, onlySearchCount, partlySubmitCount, fullySubmitCount} = itm
                        return (
                          <tr key={Math.random() * 999999999}>
                          <td>{pageNo !== 1 ? (<> {index + 1 + docPerPage * (pageNo - 1)}</>) : <>{index + 1}</>}</td>
                          <td style={{width:'20vw'}}>{name}</td>
                          <td>{mobile}</td>
                          <td>{zoneno}</td>
                          <td>{gatname.toString()}</td>
                          <td>{onlySearchCount}</td>
                          <td>{partlySubmitCount}</td>
                          <td>{fullySubmitCount}</td>
                          <td>
                            <Icon.Eye size={22} className='ms-2 me-4 icon' 
                            onClick={()=>navigate('/dashboard/view-single-survey', {state:itm} )}/> 
                          </td>
                        </tr>
                        )
                    })
                : <p className='noDataFound'>No Data Found</p>
                }
            </tbody>
        }
             
                  </table>
              </div>
          </div>
        <Row>
            <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
            <h6 className='text-start mb-0'>
                <Icon.People className='me-2'/>Total - <strong>{data?.count}</strong>
            </h6>
            </Col>
            <Col md={8} className='d-flex justify-content-end'>
                <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange} 
                onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage}/>
            </Col>
        </Row>
        </Container>
    </div>
  )
}

export default DaywiseReport;