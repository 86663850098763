import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Skeleton from '../Common/Skeleton';
import Pagination from '../Common/Pagination';
import * as Icon from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import PartlyDoc from '../../Assets/PartlySubmittedDoc.svg';
import FullyChecked from '../../Assets/FullySubmitChecked.svg';
import Updated from '../../Assets/Updated.svg';
import SMSSend from '../../Assets/SMSSend.svg';

const SmsCampaign = () => {

    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [counts, setCounts] = useState('');
    const [data, setData] = useState('');

    // States for Pagination ----------
      const [pageNo, setPageNo] = useState(1);
      const [docPerPage, setDocPerPage] = useState(50);
      const [noOfPages, setNoOfPages] = useState();

    useEffect(()=>{
        getOwnerFormCounts();
    },[])

    useEffect(()=>{
        getAllOwnerForms();
    },[pageNo, docPerPage])

    const getOwnerFormCounts = async()=>{
        await fetch(process.env.REACT_APP_BASE_URL + '/ownerform/ownerFormReport',{
            method:'GET',
        }).then((res)=>res.json()).then((response)=>{
            setCounts(response);
        }).catch((Err)=>{
            console.log("Err while getting Owner Forms Count", Err);
        })
    }

    const getAllOwnerForms = async()=>{
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/ownerform/getAllOwnerForms',{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
                documentsPerPage:docPerPage,
                page:pageNo
            })
        }).then((res)=>res.json()).then((response)=>{
            setData(response?.data);
            setNoOfPages(response?.data?.noOfPages);
            setLoader(false);
        }).catch((Err)=>{
            console.log("Err while get all owner forms", Err);
            setLoader(false);
        })
    }

     // function for pagination
     const handlePageChange = (pageNumber) => {
        setPageNo(pageNumber);
     };

    const handleDocsPerPage = (docsPageProp) => {
        setPageNo(1)
        setDocPerPage(docsPageProp);
    }



  return (
    <div className='SmsCampaign outletPadding'>
        <Container>

        <Row className='Home mt-3 justify-content-center'>
          <Col md={5} lg={3} className='mb-4'>
              <Card>
                <Card.Body>
                <img src={PartlyDoc} alt="Partly Submitted"/>
                <div className='data'>
                  <h4>{counts["Total form filled"]}</h4>
                  <h6>Forms Filled Count</h6>
                </div>
                </Card.Body>
              </Card>
            </Col>

            <Col md={5} lg={3} className='mb-4'>
              <Card>
                <Card.Body>
                <img src={FullyChecked} alt="Details Not Given"/>
                <div className='data'>
                  <h4>{counts["Total form visited"]}</h4>
                  <h6>Form Visited Count</h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={5} lg={3} className='mb-4'>
              <Card>
                <Card.Body>
                <img src={SMSSend} alt="SMS Send"/>
                <div className='data'>
                  <h4>{counts["Total SMS Sent"]}</h4>
                  <h6>SMS Send Count</h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col md={5} lg={3} className='mb-4'>
              <Card>
                <Card.Body>
                <img src={Updated} alt="Updated Count"/>
                <div className='data'>
                  <h4>{counts["Total unique form filled"]}</h4>
                  <h6>Distinct Form Count</h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            </Row>

            <div className="outer-wrapper mx-auto">
                <div className="table-wrapper" style={{maxHeight:'67vh'}}>
                    <table>
                        <thead>
                            <th>Sr. No.</th>
                            <th>Property Code</th>
                            <th>Property Name</th>
                            <th>Owner Name</th>
                            <th>Owner Mobile</th>
                            {/* <th>Property Type</th> */}
                            <th>Property Address</th>
                            <th>Business Address</th>
                            <th>Action</th>
                        </thead>

                { loader ? <Skeleton rows={8} cols={8}/> :
                <>
                    <tbody data-aos="fade-up">
                    {
                    data?.ownerForms!== null && data.ownerForms !== undefined && data?.ownerForms.length > 0 ? 
                    data?.ownerForms.map((itm, index) => {
                        const { surveyId, propertyCode, propertyName, ownerName, ownerMobile, propertyType, finalBusinessAddress, propertyAddress, } = itm

                        return (
                            <tr key={Math.random() * 999999999}>
                               <td>{pageNo !== 1 ? (<> {index + 1 + docPerPage * (pageNo - 1)}</>) : <>{index + 1}</>}</td>
                                    <td>{propertyCode ? propertyCode : '-'}</td>
                                    <td style={{ maxWidth: '15vw' }}>{propertyName ? propertyName : '-'}</td>
                                    <td style={{ maxWidth: '15vw' }}>{ownerName}</td>
                                    <td>{ownerMobile}</td>
                                    {/* <td style={{ maxWidth: '14vw' }}>{propertyType}</td> */}
                                    <td style={{ maxWidth: '18vw' }}>{propertyAddress ? propertyAddress : '-'}</td>
                                    <td style={{ maxWidth: '20vw' }}>{finalBusinessAddress}</td>
                                    <td><Icon.Eye className='ms-2 icon' size={24}
                                        onClick={() => navigate('/dashboard/view-survey', { state: surveyId })} />
                                    </td>
                            </tr>
                        )
                    })
                : <p className='noDataFound'>No Data Found</p>
                }
            </tbody>
            </>
        }
                  </table>
              </div>

        <Row>
            <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
              <h6 className='text-start mb-0'>
                  <Icon.People className='me-2'/>Total - <strong>{data?.count}</strong>
              </h6>
            </Col>
            <Col md={8} className='d-flex justify-content-end'>
              <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange} 
                  onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage}/>
            </Col>
        </Row>
    
            </div>
        </Container>
    </div>
  )
}

export default SmsCampaign;