import React, { useEffect, useState } from 'react';
import { Card, Carousel, Col, Container, Form, Row } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Pagination from '../Common/Pagination';
import Lottie from 'react-lottie';
import ImageLoader from '../../Assets/ImageLoader.json';
import NoImage from '../../Assets/NoImage.svg';
import { useNavigate } from 'react-router-dom';


const SurveyImages = () => {

    const LottieOptions={
        loop: true,
        autoplay: true,
        animationData: ImageLoader,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      }

      const navigate = useNavigate();
      const [loader, setLoader] = useState(false);
      const [category, setCategory] = useState('outsideImage');
      const [data, setData] = useState('');
      

     // States for Pagination ----------
      const [pageNo, setPageNo] = useState(1);
      const [docPerPage, setDocPerPage] = useState(50);
      const [noOfPages, setNoOfPages] = useState();


 // function for pagination
        
   const handlePageChange = (pageNumber) => {
        setPageNo(pageNumber);
    };
    
    const handleDocsPerPage = (docsPageProp) => {
        setPageNo(1)
        setDocPerPage(docsPageProp);
    }


    useEffect(()=>{
        getSurveyImages();
    },[pageNo, docPerPage, category])

    const getSurveyImages = async()=>{
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/surveys/getSurveyImages',{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({ 
                imageField:category,
                documentsPerPage:docPerPage,
                page:pageNo
            })
        }).then((res)=>res.json()).then((response)=>{
            setData(response);
            setNoOfPages(response?.noOfPages);
            setLoader(false);
        }).catch((Err)=>{
            console.log("Err while getting surveys images", Err);
            setLoader(false);
        })
    }

 

  return (
    <div className='SurveyImages outletPadding'>
        <Container>
            <Row className='justify-content-end'>
                <Col md={3}>
                    <Form.Label>Filter by image category</Form.Label>
                    <div className='d-flex'>
                        <Form.Select value={category} onChange={(e)=>{ setCategory(e.target.value)}}>
                            <option value="outsideImage" selected>Outside Images</option>
                            <option value="insideImage">Inside Images</option>
                            <option value="sathaParvanaImage">Satha Parvana Images</option>
                            <option value="sathaShopActLicenseImage">Satha Shop Act License Images</option>
                            <option value="businessParvanaImage">Business Parvana Images</option>
                            <option value="businessShopActLicenseImage">Business Shop Act License Images</option>
                            <option value="isFoodLicenseImage">Food License Images</option>
                            <option value="fireNOCImage">Fire NOC Images</option>
                            <option value="fireAnnualImage">Fire Annual Images</option>
                            <option value="fireSafetyCertificateImage">Fire Safety Certificate Images</option>
                            <option value="electricMeterImage">Electric Meter Images</option>
                            <option value="fireExtinguisherEquipmentImage">Fire Extinguisher Equipment Images</option>
                            <option value="hazardousEquipmentImage">Hazardous Equipment Images</option>
                            <option value="atticImage">Attic Images</option>
                            <option value="basementImage">Basement Images</option>
                            <option value="residentialImage">Residential Images</option>
                        </Form.Select>
                    </div>
                </Col>
            </Row>

            { loader ?  
                    <div style={{height:'65vh'}} className='d-flex justify-content-center align-items-center'>
                        <center><Lottie options={LottieOptions} className='mt-3' width={500}/>Images Loading...</center>
                    </div> :

            <Row className='mt-4'>
               {
                    data?.data!==null && data?.data!==undefined && data?.data?.length > 0 ? 
                      data?.data?.map((itm, index) => {
                        const isImage = String(itm[category]).endsWith('.jpg') ||  String(itm[category]).endsWith('.jpeg') ||  String(itm[category]).endsWith('.png');
                        const isVideo =  String(itm[category]).endsWith('.mp4') ||  String(itm[category]).endsWith('.mov') ||  String(itm[category]).endsWith('.avi');
                            return (
                            <React.Fragment key={index} >
                                {itm[category]?.length > 1 ? (
                                  <Col md={3} className='mb-4' data-aos="fade-up">
                                    <Card className='h-100'> 
                                      <Carousel>
                                          {itm[category]?.map((image, imageIndex) => {
                                            const isImage = image.endsWith('.jpg') || image.endsWith('.jpeg') || image.endsWith('.png');
                                            const isVideo = image.endsWith('.mp4') || image.endsWith('.mov') || image.endsWith('.avi');
                                        return(
                                            <Carousel.Item key={imageIndex}>
                                              <div className='card-img-wrapper'>
                                              { isImage && <img className='cardImg' src={image} alt={category} height={250} width='100%' /> }
                                              {isVideo && <video width='100%' height={250} controls> <source src={image} type="video/mp4"/></video>}
                                              </div>
                                            </Carousel.Item>
                                          )
                                          
                                    })}
                                      </Carousel>
                                    <Card.Body onClick={()=>navigate('/dashboard/view-survey', { state: itm?.surveyId })}>
                                            <h6>{itm?.surveyByName}</h6>
                                            <p>{itm?.surveyByMobile?.replace(/(\d{5})/g, '$1 ')}</p>
                                        </Card.Body>
                                    </Card>
                                  </Col>
                                ) : itm[category] === null ? 
                                        <Col md={3} className='mb-4' data-aos="fade-up">
                                        <Card className='h-100 justify-content-center align-items-center'>
                                        <div className='mt-2'> 
                                          <img src={NoImage} alt="No Image" height={100} width='100%'/>
                                          <Card.Body onClick={()=> navigate('/dashboard/view-survey', { state: itm?.surveyId })}>
                                            <h6>{itm?.surveyByName}</h6>
                                            <p>{itm?.surveyByMobile?.replace(/(\d{5})/g, '$1 ')}</p>
                                        </Card.Body>  
                                      </div> 
                                        </Card>
                                    </Col> : (
                                  <Col md={3} className='mb-4' data-aos="fade-up">
                                    <Card className='h-100'> 
                                    <div className='card-img-wrapper'>
                                      { isImage && <img className='cardImg' src={itm[category]} alt={category} height={250} width='100%'/> }
                                      { isVideo && <video width='100%' height={250} controls> <source src={itm[category]} type="video/mp4"/></video>}
                                      </div>
                                      <Card.Body onClick={()=> navigate('/dashboard/view-survey', { state: itm?.surveyId })}>
                                        <h6>{itm?.surveyByName}</h6>
                                        <p>{itm?.surveyByMobile?.replace(/(\d{5})/g, '$1 ')}</p>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                )}
                              </React.Fragment>
                            )
                        })
                    :'NO data Found'
               }
            </Row>
            }
            
            <Row>
                <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
                    <h6 className='text-start mb-0'>
                        <Icon.People className='me-2'/>Total - <strong>{data?.count}</strong>
                    </h6>
                </Col>
                <Col md={8} className='d-flex justify-content-end p-md-0'>
                    <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange} 
                    onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage}/>
                </Col>
            </Row>
        </Container>



   {/* ---------------- View Survey By Image Modal ----------------------------------------------------------- */}
          {/* <Modal dialogClassName="my-modal" show={showSurvey} backdrop='static' keyboard={false} centered onHide={()=>setShowSurvey(!showSurvey)}>        
         </Modal> */}
    </div>
  )
}

export default SurveyImages