import React from 'react';
import PageNotFoundImg from '../../Assets/404.webp';
import { Button, Col, Row } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className='PageNotFound'>
      <Row className='justify-content-center' data-aos="fade-up">
        <Col md={10} className='text-center'>
          <img src={PageNotFoundImg} className='img-fluid' alt="Page Not Found"/>
          <h1>Page Not Found</h1>
          <p>* Please, login with valid credentails to enter in the system</p>
          <Button className='primaryBtn mx-auto' onClick={()=>navigate('/')}>
              <Icon.BoxArrowInRight size={18} className='me-2'/> Go to Login
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default PageNotFound