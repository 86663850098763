import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Spinner, Form } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
// import Search from '../../Assets/Search.svg'
import Surveys from "../../Assets/Surveys.svg";
import PartlyDoc from "../../Assets/PartlySubmittedDoc.svg";
import FullyChecked from "../../Assets/FullySubmitChecked.svg";
import Updated from "../../Assets/Updated.svg";
import Plus from "../../Assets/Plus.svg";
import EqualTo from "../../Assets/EqualTo.svg";
import OwnerMobile from "../../Assets/OwnerMobile.svg";
import RentedMobile from "../../Assets/RentedShop.svg";
import Rental from "../../Assets/Rental.svg";
import Goal from "../../Assets/Goal.svg";
import Licesne from "../../Assets/License.svg";
import moment from "moment";
import { useSelector } from "react-redux";

const NoticeDashboard = () => {
  const [loader, setLoader] = useState(false);
  const [date, setDate] = useState("");
  const [cards, setCards] = useState("");
  const userState = useSelector((state) => state.surveylogin.userData.data);

  useEffect(() => {
    getDashCards();
  }, []);

  const getDashCards = async () => {
    setLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL + "/newNoticeRoute/noticeDashboardCards",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          fromDate: "",
          toDate: "",
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setCards(response);
        setLoader(false);
      })
      .catch((Err) => {
        console.log("Err while getting dashcards", Err);
        setLoader(false);
      });
  };

  return (
    <div className="Home outletPadding">
      {loader ? (
        <center>
          <Spinner />
        </center>
      ) : (
        <>
          <h6 className="headLabel">Notice Generation</h6>
          <Row className="d-flex justify-content-center">
            <Col
              md={5}
              lg={4}
              data-aos="fade-up"
              data-aos-delay="300"
              className="mb-4 d-flex flex-column flex-md-row align-items-center"
            >
              <Card className="w-100">
                <Card.Body>
                  <img src={FullyChecked} alt="Fully Checked" />
                  <div className="data">
                    <h4>{cards?.firstNoticeGenerated}</h4>
                    <h6>First Notice Generated</h6>
                  </div>
                </Card.Body>
              </Card>
              <img
                src={Plus}
                alt="Plus"
                width={25}
                className="ms-md-3 mt-3 mt-md-0"
              />
            </Col>

            <Col
              md={5}
              lg={4}
              data-aos="fade-up"
              data-aos-delay="400"
              className="mb-4 d-flex flex-column flex-md-row align-items-center"
            >
              <Card className="h-100 w-100">
                <Card.Body>
                  <img src={FullyChecked} alt="Withouy Property Code" />
                  <div className="data">
                    <h4>{cards?.secondNoticeGenerated}</h4>
                    <h6>Second Notice Generated</h6>
                  </div>
                </Card.Body>
              </Card>
              <img
                src={EqualTo}
                alt="EqualTo"
                width={25}
                className="ms-md-3 mt-3 mt-md-0"
              />
            </Col>

            <Col
              md={5}
              lg={4}
              data-aos="fade-up"
              data-aos-delay="400"
              className="mb-4 pe-0 pe-md-5"
            >
              <Card>
                <Card.Body>
                  <img src={Surveys} alt="Fully Submitted" />
                  <div className="data">
                    <h4>{cards?.totalNoticeGenerated}</h4>
                    <h6>Total Notice Generated</h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="d-flex justify-content-center">
          <Col
              md={5}
              lg={4}
              data-aos="fade-up"
              data-aos-delay="300"
              className="mb-4 d-flex flex-column flex-md-row align-items-center"
            >
              <Card className="w-100">
                <Card.Body>
                  <img src={FullyChecked} alt="Fully Checked" />
                  <div className="data">
                    <h4>{cards?.panipurothaGenerated}</h4>
                    <h6>Pani Purotha Notice Generated</h6>
                  </div>
                </Card.Body>
              </Card>
              {/* <img
                src={Plus}
                alt="Plus"
                width={25}
                className="ms-md-3 mt-3 mt-md-0"
              /> */}
            </Col>

            <Col
              md={5}
              lg={4}
              data-aos="fade-up"
              data-aos-delay="400"
              className="mb-4 d-flex flex-column flex-md-row align-items-center"
            >
              <Card className="h-100 w-100">
                <Card.Body>
                  <img src={FullyChecked} alt="Withouy Property Code" />
                  <div className="data">
                    <h4>{cards?.veejpurothaGenerated}</h4>
                    <h6>Veej Purotha Notice Generated</h6>
                  </div>
                </Card.Body>
              </Card>
              {/* <img
                src={EqualTo}
                alt="EqualTo"
                width={25}
                className="ms-md-3 mt-3 mt-md-0"
              /> */}
            </Col>
          </Row>

          <h6 className="headLabel">Notice Distribution</h6>
          <Row className="d-flex justify-content-center">
            <h4 ><strong>* First Notice</strong></h4>
            <Col
              md={5}
              lg={4}
              data-aos="fade-up"
              data-aos-delay="300"
              className="mb-4 d-flex flex-column flex-md-row align-items-center"
            >
              <Card className="w-100">
                <Card.Body>
                  <img src={FullyChecked} alt="Fully Checked" />
                  <div className="data">
                    <h4>{cards?.firstNoticeSentSystem}</h4>
                    <h6>By System</h6>
                  </div>
                </Card.Body>
              </Card>
              <img
                src={Plus}
                alt="Plus"
                width={25}
                className="ms-md-3 mt-3 mt-md-0"
              />
            </Col>

            <Col
              md={5}
              lg={4}
              data-aos="fade-up"
              data-aos-delay="400"
              className="mb-4 d-flex flex-column flex-md-row align-items-center"
            >
              <Card className="h-100 w-100">
                <Card.Body>
                  <img src={FullyChecked} alt="Withouy Property Code" />
                  <div className="data">
                    <h4>{cards?.firstNoticeSentManual}</h4>
                    <h6>By Manual</h6>
                  </div>
                </Card.Body>
              </Card>
              <img
                src={EqualTo}
                alt="EqualTo"
                width={25}
                className="ms-md-3 mt-3 mt-md-0"
              />
            </Col>

            <Col
              md={5}
              lg={4}
              data-aos="fade-up"
              data-aos-delay="400"
              className="mb-4 pe-0 pe-md-5"
            >
              <Card>
                <Card.Body>
                  <img src={Surveys} alt="Fully Submitted" />
                  <div className="data">
                    <h4>{cards?.firstNoticeSentTotal}</h4>
                    <h6>Total Notice Sent</h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col
              md={10}
              lg={8}
              data-aos="fade-up"
              data-aos-delay="400"
              className="mb-4 d-flex flex-column flex-md-row align-items-center"
            >
              <Card className="h-100 w-100">
                <Card.Body>
                  <img src={FullyChecked} alt="Withouy Property Code" />
                  <div>
                    <h5><strong>{cards?.duplicateCount}</strong> count is duplicated which is involved in system & manual.</h5>
                  </div>
                </Card.Body>
              </Card>
              <img
                src={EqualTo}
                alt="EqualTo"
                width={25}
                className="ms-md-3 mt-3 mt-md-0"
              />
            </Col>
            <Col
              md={5}
              lg={4}
              data-aos="fade-up"
              data-aos-delay="400"
              className="mb-4 pe-0 pe-md-5"
            >
              <Card>
                <Card.Body>
                  <img src={Surveys} alt="Fully Submitted" />
                  <div className="data">
                    <h4> - {cards?.duplicateCount}</h4>
                    {/* <h6>Total Notice Sent</h6> */}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col
              md={10}
              lg={8}
              data-aos="fade-up"
              data-aos-delay="400"
              className="mb-4 d-flex flex-column flex-md-row align-items-center"
            >
              <Card className="h-100 w-100">
                <Card.Body>
                  <img src={FullyChecked} alt="Withouy Property Code" />
                  <div>
                    <h5>Final Count</h5>
                  </div>
                </Card.Body>
              </Card>
              <img
                src={EqualTo}
                alt="EqualTo"
                width={25}
                className="ms-md-3 mt-3 mt-md-0"
              />
            </Col>
            <Col
              md={5}
              lg={4}
              data-aos="fade-up"
              data-aos-delay="400"
              className="mb-4 pe-0 pe-md-5"
            >
              <Card>
                <Card.Body>
                  <img src={Surveys} alt="Fully Submitted" />
                  <div className="data">
                    <h4>{cards?.finalCount}</h4>
                    {/* <h6>Total Notice Sent</h6> */}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="d-flex">
            <h4><strong>* Second Notice</strong></h4>
            <Col
              md={5}
              lg={4}
              data-aos="fade-up"
              data-aos-delay="300"
              className="mb-4 pe-0 pe-md-5"
            >
              <Card className="w-100">
                <Card.Body>
                  <img src={FullyChecked} alt="Fully Checked" />
                  <div className="data">
                    <h4>{cards?.secondNoticeSent}</h4>
                    <h6>Second Notice Sent</h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default NoticeDashboard;
