import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Form,
  Button,
  Card,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import Pagination from "../Common/Pagination";
import * as XLSX from "xlsx";
import Skeleton from "../Common/Skeleton";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

const NoticeFormat = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState();

  const userState = useSelector((state) => state.surveylogin.userData.data);
  const navigate = useNavigate();

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  useEffect(() => {
    getNoticeFormatData();
  }, [docPerPage, pageNo]);

  const getNoticeFormatData = async () => {
    setLoader(true);
    let payload = {
      documentsPerPage: docPerPage,
      page: pageNo,
    };

    await fetch(
      process.env.REACT_APP_BASE_URL + "/noticeFormat/getNoticeFormat",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setLoader(false);
        setData(response);
        setNoOfPages(response.noOfPages);
      })
      .catch((Err) => {
        console.log("Err while getting propertType", Err);
        setLoader(false);
      });
  };

  return (
    <div className="outletPadding">
        <ToastContainer />
      <Container>
      <div className="outer-wrapper mx-auto mt-4">
        <div className="table-wrapper mt-2" style={{ maxHeight: "58vh" }}>
          <table data-aos="fade-up" data-aos-delay="200">
            <thead>
              <th>Sr. No.</th>
              <th>Notice Number</th>
              <th>Notice Type</th>
              <th>Action</th>
            </thead>
            {loader ? (
              <Skeleton rows={10} cols={9} />
            ) : data?.data !== null &&
              data?.data !== undefined &&
              data?.data?.length > 0 ? (
              data?.data?.map((itm, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1 + docPerPage * (pageNo - 1)}</td>
                    <td>{itm.userInputNoticeNo}</td>
                    <td>{itm.noticeFormatName}</td>
                    <td className="d-flex align-items-center">
                        <Button
                          className="primaryBtn ms-2"
                          onClick={() => {
                            window.open(itm.noticeFormatURL,"_blank")
                          }}
                        >
                          View Notice Format
                        </Button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <p className="noDataFound">No Data Found</p>
            )}
          </table>
        </div>

        <Row>
          <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
            <h6 className="text-center">
              <Icon.BarChart className="me-2" />
              Total - <strong>{data?.count}</strong>
            </h6>
          </Col>
          <Col md={8} className="d-flex justify-content-end">
            <Pagination
              currentPage={pageNo}
              totalPages={noOfPages}
              onPageChange={handlePageChange}
              onDocsPerPage={handleDocsPerPage}
              docsPerPage={docPerPage}
            />
          </Col>
        </Row>
      </div>
      </Container>
    </div>
  );
};

export default NoticeFormat;
