import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Form,
  Button,
  Card,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import Pagination from "../Common/Pagination";
import * as XLSX from "xlsx";
import Skeleton from "../Common/Skeleton";
import GraphLoader from "../../Assets/GraphLoader.gif";
import pako from "pako";
import JSZip from "jszip";
import moment from "moment";

const NoticeSent = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [checkboxArray, setCheckBoxArray] = useState([]);
  const [zipLoader, setZipLoader] = useState(false);
  const [loaderArray, setLoaderArray] = useState([]);

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState();

  const [download, setDownload] = useState(false);

  useEffect(() => {
    getNoticeToBeGiven();
  }, [docPerPage, pageNo]);

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  const getNoticeToBeGiven = async () => {
    setLoader(true);
    let payload = {
      documentsPerPage: docPerPage,
      page: pageNo,
    };

    await fetch(
      process.env.REACT_APP_BASE_URL + "/newSurvey/getAllNoticeSentDashboard",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setLoader(false);
        setData(response);
        setNoOfPages(response.noOfPages);
      })
      .catch((Err) => {
        console.log("Err while getting propertType", Err);
        setLoader(false);
      });
  };

  // const checkBoxChnage = (event, item) => {
  //   if (event.target.checked) {
  //     let arr = [...checkboxArray];
  //     arr.push(item.noticePDF);
  //     setCheckBoxArray(arr);
  //   } else {
  //     let getvalue = checkboxArray.findIndex(
  //       (event) => event?.noticePDF === item?.noticePDF
  //     );
  //     checkboxArray.splice(getvalue, 1);
  //   }
  // };

  // const downloadExcel = async () => {
  //   try {
  //     setZipLoader(true);
  //     const zip = new JSZip();

  //     // Download each PDF and add it to the zip file
  //     await Promise.all(
  //       checkboxArray.map(async (pdfUrl, index) => {
  //         const response = await fetch(pdfUrl);
  //         const data = await response.arrayBuffer();

  //         // Compress the PDF content using pako
  //         const compressedData = await pako.deflateRaw(data);

  //         zip.file(`document${index + 1}.pdf`, compressedData);
  //       })
  //     );

  //     // Generate the zip file
  //     const zipBlob = await zip.generateAsync({ type: "blob" });

  //     // Create a link element to trigger the download
  //     const link = document.createElement("a");
  //     link.href = URL.createObjectURL(zipBlob);
  //     link.download = "pdfs.zip";
  //     document.body.appendChild(link);

  //     // Trigger the download
  //     link.click();

  //     // Remove the link element
  //     document.body.removeChild(link);
  //     setCheckBoxArray([]);
  //     setZipLoader(false);
  //   } catch (error) {
  //     console.error("Error downloading or zipping PDFs:", error);
  //     setZipLoader(false);
  //   }
  // };

  // const changeSelectAll = (event) => {
  //   if (event.target.checked) {
  //     let arr = [];
  //     data?.data.map((row) => {
  //       arr.push(row.noticePDF);
  //     });
  //     setCheckBoxArray(arr);
  //   } else {
  //     setCheckBoxArray([]);
  //   }
  // };

  const downloadNotice = (data, index) => {
    let arr = [...loaderArray];
    arr[index] = true;
    setLoaderArray(arr);

    fetch(data.noticePDF)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = data.noticeId + ".pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        let arr = [...loaderArray];
        arr[index] = false;
        setLoaderArray(arr);
      })
      .catch((error) => console.error("Error downloading PDF:", error));
  };

  return (
    <div className="outletPadding">
      {zipLoader ? (
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <img src={GraphLoader} alt="Loader" width={200} />
          <h5>Generating Zip file ...</h5>
        </div>
      ) : (
        <Container>
          <h3>Notice Sent List</h3>
          {/* <Row className="justify-content-end">
            <Col md={2}>
              <Button
                disabled={checkboxArray.length === 0}
                className="primaryBtn"
                onClick={() => downloadExcel()}
              >
                Download PDF's
              </Button>
            </Col>
          </Row> */}
          <div className="outer-wrapper mx-auto mt-4">
            {
              <>
                {/* <Form.Check
                  label={"Select All"}
                  onChange={(e) => changeSelectAll(e)}
                /> */}
                <div
                  className="table-wrapper mt-2"
                  style={{ maxHeight: download ? "45vh" : "58vh" }}
                >
                  <table data-aos="fade-up" data-aos-delay="200">
                    <thead>
                      <th>Sr. No.</th>
                      <th>Rented Person Name</th>
                      <th>Rented Person Mobile</th>
                      <th>Rented Person Adddress</th>
                      <th>Notice Generate Name</th>
                      <th>Notice Generate Mobile</th>
                      <th>Notice Generate Role</th>
                      <th>Notice Generate Date</th>
                      <th>Action</th>
                    </thead>
                    {loader ? (
                      <Skeleton rows={10} cols={9} />
                    ) : data?.data !== null &&
                      data?.data !== undefined &&
                      data?.data?.length > 0 ? (
                      data?.data?.map((itm, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {pageNo !== 1 ? (
                                <div className="d-flex">
                                  {" "}
                                  {index + 1 + docPerPage * (pageNo - 1)}
                                  {/* <Form.Check
                                    style={{ marginLeft: 5 }}
                                    name="group1"
                                    type={"checkbox"}
                                    checked={
                                      checkboxArray.filter(
                                        (row) => row === itm.noticePDF
                                      ).length > 0
                                    }
                                    onChange={(e) => checkBoxChnage(e, itm)}
                                  /> */}
                                </div>
                              ) : (
                                <div className="d-flex">
                                  {index + 1}
                                  {/* <Form.Check
                                    style={{ marginLeft: 5 }}
                                    name="group1"
                                    type={"checkbox"}
                                    checked={
                                      checkboxArray.filter(
                                        (row) => row === itm.noticePDF
                                      ).length > 0
                                    }
                                    onChange={(e) => checkBoxChnage(e, itm)}
                                  /> */}
                                </div>
                              )}
                            </td>
                            <td>
                              {itm?.rentedPersonName
                                ? itm?.rentedPersonName
                                : "-"}
                            </td>
                            <td>
                              {itm?.rentedPersonMobile
                                ? itm?.rentedPersonMobile
                                : "-"}
                            </td>
                            <td>
                              {itm?.finalBusinessAddress
                                ? itm?.finalBusinessAddress
                                : "-"}
                            </td>
                            <td>
                              {itm?.noticeGenerateName
                                ? itm?.noticeGenerateName
                                : "-"}
                            </td>
                            <td>
                              {itm?.noticeGenerateMobile
                                ? itm?.noticeGenerateMobile
                                : "-"}
                            </td>
                            <td>
                              {itm?.noticeGenerateRole
                                ? itm?.noticeGenerateRole.replace(
                                    /^./,
                                    itm?.noticeGenerateRole[0].toUpperCase()
                                  )
                                : "-"}
                            </td>
                            <td>
                              {itm?.noticeGenerateDate
                                ? moment(itm?.noticeGenerateDate).format(
                                    "DD-MM-YYYY"
                                  )
                                : "-"}
                            </td>
                            <td>
                              {loaderArray[index] ? (
                                <Spinner />
                              ) : (
                                <Button
                                  className="primaryBtn"
                                  onClick={() => downloadNotice(itm, index)}
                                >
                                  Download Notice
                                </Button>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <p className="noDataFound">No Data Found</p>
                    )}
                  </table>
                </div>

                <Row>
                  <Col
                    md={4}
                    className="d-flex align-items-center mt-3 mt-md-0"
                  >
                    <h6 className="text-center">
                      <Icon.BarChart className="me-2" />
                      Total - <strong>{data?.count}</strong>
                    </h6>
                  </Col>
                  <Col md={8} className="d-flex justify-content-end">
                    <Pagination
                      currentPage={pageNo}
                      totalPages={noOfPages}
                      onPageChange={handlePageChange}
                      onDocsPerPage={handleDocsPerPage}
                      docsPerPage={docPerPage}
                    />
                  </Col>
                </Row>
              </>
            }
          </div>
        </Container>
      )}
    </div>
  );
};

export default NoticeSent;
